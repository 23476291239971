const INITIAL_STATE = {
    currentUser: null
};
const userReducer = (state = INITIAL_STATE, action) => {
    const strategy = strategies[action.type] ?? strategies.__default__;
    return strategy(state, action);
};

const strategies = {
    SET_CURRENT_USER: setCurrentUser,
    __default__: state => state
};


//strategies
function setCurrentUser(state, action) {
    return {
        ...state,
        currentUser: action.payload
    };
}

export default userReducer;