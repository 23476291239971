import React from "react";
import "./retailer-consignment-filter.scss";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRetailerConsignments } from "../../../redux/catalog/catalog.actions";
import { firestore } from "../../../firebase/firebase.utils";
import RetailerConsignmentList from "../../../components/common/consignment/retailer-consignment-list/retailer-consignment-list.component";
import Typography from "@material-ui/core/Typography";

class RetailerConsignmentFilterPage extends React.Component {
    unsubscribeSnapshot = null;
    status = "";

    componentWillMount() {
        const query = new URLSearchParams(this.props.location.search);
        this.status = query.get("status");
    }

    componentDidMount() {
        const { setRetailerConsignments } = this.props;
        this.unsubscribeSnapshot = firestore
            .collection("retailerConsignments")
            .where("status", "==", this.status)
            .onSnapshot((querySnapshot) => {
                let consignments = {};
                querySnapshot.forEach(function (doc) {
                    let consignment = doc.data();
                    consignments[consignment.id] = consignment;
                });
                setRetailerConsignments(consignments);
            });
    }

    componentWillUnmount() {
        this.unsubscribeSnapshot();
        const { setRetailerConsignments } = this.props;
        setRetailerConsignments([]);
    }

    render() {
        return (
            <div>
                <Typography
                    variant="subtitle1"
                    gutterBottom
                    style={{ textAlign: "center" }}
                >
                    Retailer shipments: {this.status}
                </Typography>
                <RetailerConsignmentList />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setRetailerConsignments: (consignments) =>
        dispatch(setRetailerConsignments(consignments)),
});

export default withRouter(
    connect(null, mapDispatchToProps)(RetailerConsignmentFilterPage)
);
