import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import AdminRetailerNewComponent from "../../../components/admin/admin-retailer-new/admin-retailer-new.component";
import {firestore} from "../../../firebase/firebase.utils";
import {
    setAdminCountries,
    setAdminCurrent,
    setAdminLanguages, setAdminRetailerTypes,
    setAdminStates
} from "../../../redux/admin/admin.actions";

class AdminRetailerNewPage extends React.Component {
    unsubscribeSnapshot = null;

    componentWillMount() {}

    componentDidMount() {
        const { setCurrent, setCountries, setStates, setLanguages, setRetailerTypes } = this.props;
        let retailer = {
            address: {
                city: "",
                country: "India",
                pincode: "",
                state: "Maharashtra",
                streetAddress: ""
            },
            country: "India",
            createdOn: null,
            email: "",
            gstNo: "",
            id: "",
            language: "en",
            name: "",
            phone: "",
            photoUrl: "retailer_profile_photos/placeholder_profile_photo.png",
            retailerType: "Retailer",
            state: "Maharashtra",
            updatedOn: null
        };
        setCurrent(retailer);
        setCountries(null);
        setStates(null);
        setLanguages(null);
        setRetailerTypes(null);

        firestore
            .collection("countries")
            .get()
            .then((querySnapshot) => {
                let countries = {};
                querySnapshot.forEach(function (doc) {
                    let country = doc.data();
                    countries[country.code] = country;
                });
                setCountries(countries);
            });
        firestore
            .collection("states")
            .get()
            .then((querySnapshot) => {
                let states = {};
                querySnapshot.forEach(function (doc) {
                    let state = doc.data();
                    states[state.code] = state;
                });
                setStates(states);
            });
        firestore
            .collection("languages")
            .get()
            .then((querySnapshot) => {
                let languages = {};
                querySnapshot.forEach(function (doc) {
                    let language = doc.data();
                    languages[language.code] = language;
                });
                setLanguages(languages);
            });
        firestore
            .collection("retailerTypes")
            .get()
            .then((querySnapshot) => {
                let retailerTypes = {};
                querySnapshot.forEach(function (doc) {
                    let retailerType = doc.data();
                    retailerTypes[retailerType.code] = retailerType;
                });
                setRetailerTypes(retailerTypes);
            });
    }

    componentWillUnmount() {
        if(this.unsubscribeSnapshot)
            this.unsubscribeSnapshot();
        const { setCurrent } = this.props;
        setCurrent(null);
    }

    render() {
        return (
            <div>
                {/*<Typography*/}
                    {/*variant="subtitle1"*/}
                    {/*gutterBottom*/}
                    {/*style={{ textAlign: "center" }}*/}
                {/*>*/}
                    {/*New retailer*/}
                {/*</Typography>*/}
                <AdminRetailerNewComponent />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setCurrent: (retailer) => dispatch(setAdminCurrent(retailer)),
    setCountries: (countries) => dispatch(setAdminCountries(countries)),
    setStates: (states) => dispatch(setAdminStates(states)),
    setLanguages: (languages) => dispatch(setAdminLanguages(languages)),
    setRetailerTypes: (retailerTypes) => dispatch(setAdminRetailerTypes(retailerTypes)),
});

export default withRouter(connect(null, mapDispatchToProps)(AdminRetailerNewPage));
