import React from "react";
import "./style.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {
    setAdminRetailerWarehouses,
    setAdminCurrent,
} from "../../../redux/admin/admin.actions";
import { firestore } from "../../../firebase/firebase.utils";
import firebase from "../../../firebase/firebase.utils";
import Grid from "@material-ui/core/Grid";
import {calculateMargin, calculateMinSalesPrice, generateRandomNumber} from "../../../utils/functions";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: "36ch",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
        overflowX: "auto",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const AdminNewCustomerOrderComponent = ({
    states,
    form,
    products,
    retailers,
    fishTypes,
    currentUser,
    setCurrent,
    history,
}) => {
    const classes = useStyles();

    let servingTypes = {};

    if (products && fishTypes) {
        for (let i = 0; i < Object.keys(products).length; i++) {
            const key = Object.keys(products)[i];
            servingTypes[products[key].id] = fishTypes[products[key].fishType]
                ? fishTypes[products[key].fishType].servingTypes
                    ? fishTypes[products[key].fishType].servingTypes
                    : []
                : [];
        }
    }

    const handleChange = (e, attr, i) => {
        const value = e.target.value;
        let formNew = [...form];
        formNew[i][attr] = value;
        setCurrent(formNew);
    };

    const handleDateChange = (e, i) => {
        let formNew = [...form];
        formNew[i]["orderDate"] = new Date(e.target.value);
        setCurrent(formNew);
    };

    const handleItemChange = (e, attr, i, j) => {
        const value = e.target.value;
        let formNew = [...form];
        formNew[i]["orderItems"][j][attr] = value;
        if (attr === "productId") {
            formNew[i]["orderItems"][j]["product"] = products[value];
        }
        setCurrent(formNew);
    };

    const addRow = () => {
        let formNew = [...form];
        formNew.push({
            orderDate: new Date(),
            name: "",
            phone: "",
            streetAddress: "",
            landmark: "",
            city: "",
            zone: "",
            // state: "",
            pincode: "",
            orderItems: [],
            paymentStatus: "",
            paymentMode: "",
            driverName: "",
            driverPhone: "",
            deliveryChannel: "",
            deliveryCharge: "",
            dispatchedFrom: "",
        });
        setCurrent(formNew);
    };

    const addOrderItem = (i) => {
        let formNew = [...form];
        let orderItem = {
            productId: "",
            quantity: 0,
            product: null,
            pricePerKg: 0,
            servingType: "",
        };
        formNew[i].orderItems.push(orderItem);
        setCurrent(formNew);
    };

    const removeRow = (i) => {
        let formNew = [...form];
        formNew.splice(i, 1);
        setCurrent(formNew);
    };

    const removeRowOrderItem = (i, j) => {
        let formNew = [...form];
        formNew[i].orderItems.splice(j, 1);
        setCurrent(formNew);
    };

    let errors = [];

    if (form) {
        for (let i = 0; i < form.length; i++) {
            const row = form[i];
            let orderItemErrors = [];
            if(!row.orderItems)
                continue;
            for (let j = 0; j < row.orderItems.length; j++) {
                orderItemErrors.push({
                    productId:
                        !row.orderItems[j].productId ||
                        row.orderItems[j].productId === "" ||
                        row.orderItems[j].productId === "default",
                    quantity:
                        !row.orderItems[j].quantity ||
                        row.orderItems[j].quantity === "" ||
                        parseFloat(row.orderItems[j].quantity) === 0,
                    pricePerKg:
                        !row.orderItems[j].pricePerKg ||
                        row.orderItems[j].pricePerKg === "" ||
                        parseFloat(row.orderItems[j].pricePerKg) === 0,
                    servingType:
                        !row.orderItems[j].servingType ||
                        row.orderItems[j].servingType === "" ||
                        row.orderItems[j].servingType === "default",
                });
            }
            let emptyError = {
                orderDate: !row.orderDate,
                name: !row.name || row.name === "",
                phone: !row.phone || row.phone === "",
                streetAddress: !row.streetAddress || row.streetAddress === "",
                landmark: false,
                city: !row.city || row.city === "",
                zone: !row.zone || row.zone === "",
                pincode: false,
                orderItems: !row.orderItems || row.orderItems.length === 0,
                paymentStatus: !row.paymentStatus || row.paymentStatus === "" || row.paymentStatus==="default",
                paymentMode: !row.paymentMode || row.paymentMode === "" || row.paymentMode==="default",
                driverName: false,
                driverPhone: false,
                deliveryChannel: false,
                deliveryCharge:
                    !row.deliveryCharge ||
                    row.deliveryCharge === "" ||
                    parseFloat(row.deliveryCharge) < 0,
                dispatchedFrom: false,
                orderItemErrors: orderItemErrors,
            };
            errors.push(emptyError);
        }
    }

    const isFormValid = ()=>{
        let isValid = true;
        if(!form)
            isValid = false;
        if(form && form.length===0)
            isValid = false;
        for (let i = 0; i < errors.length; i++) {
            Object.keys(errors[i]).map(key=>{
                if(key==="orderItemErrors"){
                    for (let j=0;j<errors[i][key].length;j++){
                        Object.keys(errors[i][key][j]).map(itemKey=>{
                            if (errors[i][key][j][itemKey])
                                isValid = false;
                        });
                    }
                }
                if (errors[i][key] && key!=="orderItemErrors")
                    isValid = false;
            })
        }
        return isValid;
    };

    const flag = isFormValid();

    const createOrder = async () => {
        if(!isFormValid()){
            return;
        }
        let batch = firestore.batch();

        for (let i = 0; i < form.length; i++) {
            let row = form[i];
            const orderId = generateRandomNumber(16);
            const deliveryAddressId = generateRandomNumber(16);
            const invoiceId = orderId;//generateRandomNumber(16);

            let orderItems = [];
            let invoiceItems = [];
            let totalAmount = 0;
            for (let j=0;j<row.orderItems.length;j++){
                const orderItemRaw = row.orderItems[j];
                const orderItemId = generateRandomNumber(16);

                let selectedRowProduct = orderItemRaw.product;

                let margin = calculateMargin(selectedRowProduct.purchase, parseFloat(orderItemRaw.pricePerKg));
                if(margin<0){
                    alert("Sales price is less than Product price(including all costs)");
                    return;
                }
                const amount = (parseFloat(orderItemRaw.quantity) * parseFloat(orderItemRaw.pricePerKg));
                totalAmount += amount;
                orderItems.push({
                    id: orderItemId,
                    agentId: selectedRowProduct.agentId,
                    orderId: orderId,
                    customerProductId: selectedRowProduct.id,
                    customerId: null,
                    pricePerKg: parseFloat(orderItemRaw.pricePerKg),
                    quantity: parseFloat(orderItemRaw.quantity),
                    servingType: orderItemRaw.servingType,
                    status: "ACTIVE",
                    customerProduct: selectedRowProduct,
                    updatedOn: null,
                    createdOn: firebase.firestore.Timestamp.fromDate(row.orderDate)
                });
                invoiceItems.push({
                    id: invoiceId,
                    orderId: orderId,
                    customerId: null,
                    customerProductId: selectedRowProduct.id,
                    customerOrderItemId: orderItemId,
                    fishType: selectedRowProduct.fishType,
                    fishCategory: selectedRowProduct.fishCategory,
                    servingType: orderItemRaw.servingType,
                    quantity: parseFloat(orderItemRaw.quantity),
                    pricePerKg: parseFloat(orderItemRaw.pricePerKg),
                    totalAmount: amount,
                });
            }

            let order = {
                id: orderId,
                customerId: null,
                phone: row.phone,
                orderItems,
                deliveryDetails:{
                    driverName: row.driverName,
                    driverPhone: row.driverPhone,
                    deliveryCharge: parseFloat(row.deliveryCharge),
                    deliveryChannel: row.deliveryChannel,
                    deliveryAddressId: deliveryAddressId
                },
                createdOn: firebase.firestore.Timestamp.fromDate(row.orderDate),
                createdBy: currentUser && currentUser.id?currentUser.id:"3P0U7bkFVNZV2TjUPFZbS4FdHNJ2",
                dispatchedFrom: row.dispatchedFrom,
                dispatchedOn: firebase.firestore.Timestamp.fromDate(row.orderDate),
                transactions: [],
                remarks: [],
                arrivedOn: firebase.firestore.Timestamp.fromDate(row.orderDate),
                updatedOn: null,
                status: row.paymentStatus === "PAID" ? "PAID" : "CLOSED"
            };
            let invoice = {
                createdBy: currentUser && currentUser.id?currentUser.id:"3P0U7bkFVNZV2TjUPFZbS4FdHNJ2",
                accountDetails: {
                    accountNo: "38413341996",
                    branch: "State Bank of India, Bandra West",
                    ifsc: "SBIN0050458",
                    name: "Numer8 Analytics",
                    pan: "AAGCN0306F",
                },
                numer8Details: {
                    address: "37B Perry cross road",
                    city: "Mumbai",
                    country: "India",
                    email: "devleena@numer.in",
                    gstNo: "27AAGCN0306F1ZW",
                    name: "Numer8 Analytics Pvt Ltd",
                    phone: "+91 9916064956",
                    state: "Maharashtra",
                },
                createdOn: firebase.firestore.Timestamp.fromDate(row.orderDate),
                id: invoiceId,
                invoiceItems: invoiceItems,
                orderId: orderId,
                paymentMode: row.paymentMode,
                customerDetails: {
                    address: row.streetAddress,
                    city: row.city,
                    country: "India",
                    phone: row.phone,
                    name: row.name,
                    state: "Maharashtra",
                },
                customerId: null,
                status: row.paymentStatus,
                totalAmount: totalAmount,
                updatedOn: null,
            };
            let deliveryAddress = {
                id: deliveryAddressId,
                customerId: null,
                name: row.name,
                customerName: row.name,
                phone: row.phone,
                streetAddress: row.streetAddress,
                landmark: row.landmark,
                zone: row.zone,
                pincode: row.pincode,
                state: "Maharashtra",
                country: "India",
                city: row.city,
                createdBy: currentUser && currentUser.id?currentUser.id:"3P0U7bkFVNZV2TjUPFZbS4FdHNJ2",
                status: "ACTIVE",
                createdOn: firebase.firestore.Timestamp.fromDate(row.orderDate)
            };

            console.log("Order", order);
            console.log("Invoice", invoice);
            console.log("Address", deliveryAddress);

            let deliveryAddressRef = firestore.collection("customerDeliveryAddresses").doc(deliveryAddressId);
            batch.set(deliveryAddressRef, deliveryAddress);
            let orderRef = firestore.collection("customerOrders").doc(orderId);
            batch.set(orderRef, order);
            let invoiceRef = firestore.collection("customerInvoices").doc(invoiceId);
            batch.set(invoiceRef, invoice);
        }
        batch.commit().then(function () {
            history.push(`${process.env.PUBLIC_URL}/`);
        });
    };

    return products ? (
        <Container>
            <Typography variant={"h6"}>New order</Typography>
            <div style={{ width: "100%", overflowX: "auto" }}>
                {form && form.length > 0 ? (
                    <table className={"form-table"}>
                        <thead>
                            <tr>
                                <th>Sno</th>
                                <th>Order date*</th>
                                <th>Customer name*</th>
                                <th>Phone*</th>
                                <th>Street address*</th>
                                <th>Landmark</th>
                                <th>City*</th>
                                <th>Zone*</th>
                                {/*<th>State*</th>*/}
                                <th>Pincode</th>
                                <th>Order Items*<br/>Product/Quantity/PricePerKg/ServingType</th>
                                <th>Payment status*</th>
                                <th>Payment mode*</th>
                                <th>Delivery driver name</th>
                                <th>Delivery driver phone</th>
                                <th>Delivery channel</th>
                                <th>Delivery charge</th>
                                <th>Dispatched from</th>
                            </tr>
                        </thead>
                        <tbody>
                            {form.map((row, i) => {
                                return row.orderItems?(
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>
                                            <input
                                                className={
                                                    errors[i].orderDate
                                                        ? "error"
                                                        : ""
                                                }
                                                value={
                                                    row.orderDate
                                                        .toISOString()
                                                        .split("T")[0]
                                                }
                                                placeholder={""}
                                                onChange={(e) => {
                                                    handleDateChange(e, i);
                                                }}
                                                type={"date"}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                value={row.name}
                                                className={
                                                    errors[i].name
                                                        ? "error"
                                                        : ""
                                                }
                                                placeholder={"Customer name"}
                                                onChange={(e) => {
                                                    handleChange(e, "name", i);
                                                }}
                                                type={"text"}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                value={row.phone}
                                                className={
                                                    errors[i].phone
                                                        ? "error"
                                                        : ""
                                                }
                                                placeholder={"Phone"}
                                                onChange={(e) => {
                                                    handleChange(e, "phone", i);
                                                }}
                                                type={"text"}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                value={row.streetAddress}
                                                className={
                                                    errors[i].streetAddress
                                                        ? "error"
                                                        : ""
                                                }
                                                placeholder={"Street address"}
                                                onChange={(e) => {
                                                    handleChange(
                                                        e,
                                                        "streetAddress",
                                                        i
                                                    );
                                                }}
                                                type={"text"}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                value={row.landmark}
                                                className={
                                                    errors[i].landmark
                                                        ? "error"
                                                        : ""
                                                }
                                                placeholder={"Landmark"}
                                                onChange={(e) => {
                                                    handleChange(
                                                        e,
                                                        "landmark",
                                                        i
                                                    );
                                                }}
                                                type={"text"}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                value={row.city}
                                                className={
                                                    errors[i].city
                                                        ? "error"
                                                        : ""
                                                }
                                                placeholder={"City"}
                                                onChange={(e) => {
                                                    handleChange(e, "city", i);
                                                }}
                                                type={"text"}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                value={row.zone}
                                                className={
                                                    errors[i].zone
                                                        ? "error"
                                                        : ""
                                                }
                                                placeholder={"Zone"}
                                                onChange={(e) => {
                                                    handleChange(e, "zone", i);
                                                }}
                                                type={"text"}
                                            />
                                        </td>
                                        {/*<td>*/}
                                        {/*<select*/}
                                        {/*value={"Maharashtra"}*/}
                                        {/*// className={errors[i].retailerId?'error':""}*/}
                                        {/*// onChange={(e) => {*/}
                                        {/*//     handleChange(e, "retailerId", i);*/}
                                        {/*// }}*/}
                                        {/*>*/}
                                        {/*<option value={"default"}>Select state</option>*/}
                                        {/*{Object.keys(states).map((key) => {*/}
                                        {/*let state = states[key];*/}
                                        {/*return (*/}
                                        {/*<option*/}
                                        {/*key={state.code}*/}
                                        {/*value={state.code}*/}
                                        {/*>*/}
                                        {/*{state.name}*/}
                                        {/*</option>*/}
                                        {/*);*/}
                                        {/*})}*/}
                                        {/*</select>*/}
                                        {/*</td>*/}
                                        <td>
                                            <input
                                                value={row.pincode}
                                                className={
                                                    errors[i].pincode
                                                        ? "error"
                                                        : ""
                                                }
                                                placeholder={"Pincode"}
                                                onChange={(e) => {
                                                    handleChange(
                                                        e,
                                                        "pincode",
                                                        i
                                                    );
                                                }}
                                                type={"text"}
                                            />
                                        </td>
                                        <td>
                                            {row.orderItems.map(
                                                (orderItem, j) => {
                                                    return (
                                                        <div
                                                            style={{
                                                                whiteSpace:
                                                                    "nowrap",
                                                                // border: '1px solid #344955',
                                                                padding: 5,
                                                                borderRadius: 3
                                                            }}
                                                        >
                                                            <select
                                                                    value={
                                                                        orderItem.productId
                                                                    }
                                                                    className={
                                                                        errors[i]
                                                                            .orderItemErrors[
                                                                            j
                                                                        ].productId
                                                                            ? "error"
                                                                            : ""
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        handleItemChange(
                                                                            e,
                                                                            "productId",
                                                                            i,
                                                                            j
                                                                        );
                                                                    }}
                                                                >
                                                                    <option
                                                                        value={
                                                                            "default"
                                                                        }
                                                                    >
                                                                        Select
                                                                        product
                                                                    </option>
                                                                    {Object.keys(
                                                                        products
                                                                    ).map((key) => {
                                                                        let product =
                                                                            products[
                                                                                key
                                                                            ];
                                                                        return (
                                                                            <option
                                                                                key={
                                                                                    product.id
                                                                                }
                                                                                value={
                                                                                    product.id
                                                                                }
                                                                            >
                                                                                {`${
                                                                                    product.id
                                                                                } - ${
                                                                                    product.fishType
                                                                                }, ${
                                                                                    product.fishCategory
                                                                                }(${product.purchase.caughtOn
                                                                                    .toDate()
                                                                                    .toLocaleDateString()}, ${product.quantityToSellInKg} Kg, Min. Price: ₹${calculateMinSalesPrice(
                                                                                    product.purchase
                                                                                )})`}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            {/*<br/>*/}
                                                            {/*<label>Quantity: </label><br/>*/}
                                                            <input
                                                                value={
                                                                    orderItem.quantity
                                                                }
                                                                className={
                                                                    errors[i]
                                                                        .orderItemErrors[
                                                                        j
                                                                    ]
                                                                        .quantity
                                                                        ? "error"
                                                                        : ""
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    handleItemChange(
                                                                        e,
                                                                        "quantity",
                                                                        i,
                                                                        j
                                                                    );
                                                                }}
                                                                type={"number"}
                                                            />
                                                            {/*<br/>*/}
                                                            {/*<label>Price Per Kg: </label><br/>*/}
                                                            <input
                                                                value={
                                                                    orderItem.pricePerKg
                                                                }
                                                                className={
                                                                    errors[i]
                                                                        .orderItemErrors[
                                                                        j
                                                                    ]
                                                                        .pricePerKg
                                                                        ? "error"
                                                                        : ""
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    handleItemChange(
                                                                        e,
                                                                        "pricePerKg",
                                                                        i,
                                                                        j
                                                                    );
                                                                }}
                                                                type={"number"}
                                                            />
                                                            {/*<br/>*/}
                                                            {/*<label>Serving type: </label><br/>*/}
                                                            <select
                                                                value={
                                                                    orderItem.servingType
                                                                }
                                                                className={
                                                                    errors[i]
                                                                        .orderItemErrors[
                                                                        j
                                                                    ]
                                                                        .servingType
                                                                        ? "error"
                                                                        : ""
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    handleItemChange(
                                                                        e,
                                                                        "servingType",
                                                                        i,
                                                                        j
                                                                    );
                                                                }}
                                                            >
                                                                <option
                                                                    value={
                                                                        "default"
                                                                    }
                                                                >
                                                                    Select
                                                                    serving type
                                                                </option>
                                                                {servingTypes[
                                                                    orderItem
                                                                        .productId
                                                                ]
                                                                    ? servingTypes[
                                                                          orderItem
                                                                              .productId
                                                                      ].map(
                                                                          (
                                                                              servingType
                                                                          ) => {
                                                                              return (
                                                                                  <option
                                                                                      key={
                                                                                          servingType.name
                                                                                      }
                                                                                      value={
                                                                                          servingType.name
                                                                                      }
                                                                                  >
                                                                                      {
                                                                                          servingType.name
                                                                                      }
                                                                                  </option>
                                                                              );
                                                                          }
                                                                      )
                                                                    : ""}
                                                            </select>
                                                            {/*<br/>*/}
                                                                <button
                                                                    onClick={() => {
                                                                        removeRowOrderItem(
                                                                            i,
                                                                            j
                                                                        );
                                                                    }}
                                                                >
                                                                    Delete
                                                                </button>
                                                        </div>
                                                    );
                                                }
                                            )}

                                            <button
                                                className={
                                                    errors[i].orderItems
                                                        ? "error"
                                                        : ""
                                                }
                                                onClick={() => {
                                                    addOrderItem(i);
                                                }}
                                            >
                                                Add item
                                            </button>
                                        </td>
                                        <td>
                                            <select
                                                value={row.paymentStatus}
                                                className={errors[i].paymentStatus?'error':""}
                                                onChange={(e) => {
                                                    handleChange(
                                                        e,
                                                        "paymentStatus",
                                                        i
                                                    );
                                                }}
                                            >
                                                <option value={"default"}>
                                                    Select payment status
                                                </option>
                                                <option value={"UNPAID"}>
                                                    Not paid
                                                </option>
                                                <option value={"PAID"}>
                                                    Paid
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <select
                                                value={row.paymentMode}
                                                className={errors[i].paymentMode?'error':""}
                                                onChange={(e) => {
                                                    handleChange(
                                                        e,
                                                        "paymentMode",
                                                        i
                                                    );
                                                }}
                                            >
                                                <option value={"default"}>
                                                    Select payment mode
                                                </option>
                                                <option value={"ONLINE"}>
                                                    Online
                                                </option>
                                                <option value={"PAYTM"}>
                                                    Paytm
                                                </option>
                                                <option value={"GPAY"}>
                                                    Google pay
                                                </option>
                                                <option value={"CASH"}>
                                                    Cash
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <input
                                                value={row.driverName}
                                                className={errors[i].driverName?'error':""}
                                                placeholder={"Driver name"}
                                                onChange={(e) => {
                                                    handleChange(
                                                        e,
                                                        "driverName",
                                                        i
                                                    );
                                                }}
                                                type={"text"}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                value={row.driverPhone}
                                                className={errors[i].driverPhone?'error':""}
                                                placeholder={"Driver phone"}
                                                onChange={(e) => {
                                                    handleChange(
                                                        e,
                                                        "driverPhone",
                                                        i
                                                    );
                                                }}
                                                type={"text"}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                value={row.deliveryChannel}
                                                className={errors[i].deliveryChannel?'error':""}
                                                placeholder={"Delivery channel"}
                                                onChange={(e) => {
                                                    handleChange(
                                                        e,
                                                        "deliveryChannel",
                                                        i
                                                    );
                                                }}
                                                type={"text"}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                value={row.deliveryCharge}
                                                className={errors[i].deliveryCharge?'error':""}
                                                placeholder={"Delivery charge"}
                                                onChange={(e) => {
                                                    handleChange(
                                                        e,
                                                        "deliveryCharge",
                                                        i
                                                    );
                                                }}
                                                type={"number"}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                value={row.dispatchedFrom}
                                                className={errors[i].dispatchedFrom?'error':""}
                                                placeholder={"Dispatched from"}
                                                onChange={(e) => {
                                                    handleChange(
                                                        e,
                                                        "dispatchedFrom",
                                                        i
                                                    );
                                                }}
                                                type={"text"}
                                            />
                                        </td>
                                        <td>
                                            <button
                                                onClick={() => {
                                                    removeRow(i);
                                                }}
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ):"";
                            })}
                        </tbody>
                    </table>
                ) : (
                    ""
                )}
                <button
                    onClick={() => {
                        addRow();
                    }}
                >
                    Add order
                </button>
                <button disabled={!flag}
                        onClick={()=>{createOrder();}}
                >Submit</button>
            </div>
        </Container>
    ) : (
        ""
    );
};

const mapStateToProps = ({ admin, user }) => ({
    products: admin.customerProducts,
    fishTypes: admin.fishTypes,
    form: admin.current,
    states: admin.states,
    currentUser: user.currentUser,
});
const mapDispatchToProps = (dispatch) => ({
    setCurrent: (retailer) => dispatch(setAdminCurrent(retailer)),
    setAdminRetailerWarehouses: (retailers) =>
        dispatch(setAdminRetailerWarehouses(retailers)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AdminNewCustomerOrderComponent)
);
