import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Container from "@material-ui/core/Container";
import { firestore } from "../../../firebase/firebase.utils";
import Typography from "@material-ui/core/Typography";
import FishTypeNewComponent from "../../../components/admin/fish-type-new/fish-type-new.component";
import {setAdminCurrent} from "../../../redux/admin/admin.actions";

class FishTypeEditPage extends React.Component {

    componentDidMount() {
        const { setCurrent } = this.props;
        setCurrent({
            categories: [],
            code: "",
            fishType: "",
            name: "",
            photoUrl: "fish_type_photos/fish_placeholder.png",
            servingTypes: []
        })
    }

    componentWillUnmount() {
        const { setCurrent } = this.props;
        setCurrent(null);
    }

    render() {
        return (
            <Container>
                <Typography
                    variant="subtitle1"
                    gutterBottom
                    style={{ textAlign: "center" }}
                >
                    Fish type: New
                </Typography>
                <FishTypeNewComponent/>
            </Container>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setCurrent: (fishType) => dispatch(setAdminCurrent(fishType)),
});

export default withRouter(
    connect(null, mapDispatchToProps)(FishTypeEditPage)
);
