export const setCurrent = (current) => {
    return {
        type: 'SET_CURRENT',
        payload: current,
    };
};
// export const setCountries = (countries) => {
//     return {
//         type: 'SET_COUNTRIES',
//         payload: countries,
//     };
// };
// export const setStates = (states) => {
//     return {
//         type: 'SET_STATES',
//         payload: states,
//     };
// };
// export const setLanguages = (languages) => {
//     return {
//         type: 'SET_LANGUAGES',
//         payload: languages,
//     };
// };
// export const setRetailerTypes = (retailerTypes) => {
//     return {
//         type: 'SET_RETAILER_TYPES',
//         payload: retailerTypes,
//     };
// };
// export const setAdminRetailers = (retailers) => {
//     return {
//         type: 'SET_ADMIN_RETAILERS',
//         payload: retailers,
//     };
// };
// export const setAdminFishTypes = (fishTypes) => {
//     return {
//         type: 'SET_ADMIN_FISH_TYPES',
//         payload: fishTypes,
//     };
// };
// export const setAdminFishCategories = (fishCategories) => {
//     return {
//         type: 'SET_ADMIN_FISH_CATEGORIES',
//         payload: fishCategories,
//     };
// };
// export const setAdminRetailerWarehouses = (warehouses) => {
//     return {
//         type: 'SET_ADMIN_RETAILER_WAREHOUSES',
//         payload: warehouses,
//     };
// };
