export const calculateMinSalesPrice = (purchase) => {
    const agentCost = purchase.agentCost;
    let percentCost =
        agentCost.auctioner + agentCost.commission + agentCost.partywala;
    let absCost =
        agentCost.cleaningCutting +
        agentCost.ice +
        agentCost.landing +
        agentCost.loader +
        agentCost.misc +
        agentCost.packaging +
        agentCost.transportation +
        agentCost.wastage;
    let agentCostTotal =
        (percentCost * purchase.fishermanPrice) / 100 + absCost;
    return agentCostTotal + purchase.fishermanPrice;
};

export const calculateMargin = (purchase, productPrice) => {
    const agentCost = purchase.agentCost;
    let percentCost = agentCost.auctioner + agentCost.commission + agentCost.partywala;
    let absCost = agentCost.cleaningCutting + agentCost.ice + agentCost.landing +
        agentCost.loader + agentCost.misc + agentCost.packaging +
        agentCost.transportation + agentCost.wastage;
    let agentCostTotal = (percentCost * purchase.fishermanPrice/100) + absCost;
    let marginAmount = productPrice - agentCostTotal - purchase.fishermanPrice;
    let margin = marginAmount * 100 / purchase.fishermanPrice;
    return (margin);
};

export const generateRandomNumber = (length) => {
    let result = "";
    const characters = "0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
};