import React from "react";
import "./retailer-order-list.scss";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { connect } from "react-redux";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import {Link, withRouter} from "react-router-dom";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: "36ch",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
    },
}));

const RetailerOrderList = ({ orders, history }) => {
    const classes = useStyles();

    const viewInvoice = (id) => {
        openInTab("https://us-central1-ofish-53c9f.cloudfunctions.net/GetRetailInvoice" + "?invoiceId=" + id)
    }

    const openInTab = (url) => {
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        link.parentElement.removeChild(link);
    }


    return (
        <Container>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Sno</TableCell>
                            <TableCell align="center">ID</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">
                                No. of Shipments
                            </TableCell>
                            <TableCell align="center">Order date</TableCell>
                            <TableCell align="center">
                                Requested Shipment date
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(orders).map((key, i) => {
                            const order = orders[key];
                            return (
                                <TableRow key={order.id}
                                          hover={true}
                                className={'clickable-row'}
                                onClick={()=>{
                                    history.push(`${process.env.PUBLIC_URL}/retailerOrder/${order.id}`)
                                }}>
                                    <TableCell component="th" scope="row">
                                        {i + 1}
                                    </TableCell>
                                    <TableCell align="center">
                                        {order.id}
                                    </TableCell>
                                    <TableCell align="center">
                                        {order.status}
                                    </TableCell>
                                    <TableCell align="center">
                                        {order.consignmentCount}
                                    </TableCell>
                                    <TableCell align="center">
                                        {order.orderedOn
                                            .toDate()
                                            .toLocaleDateString()}
                                    </TableCell>
                                    <TableCell align="center">
                                        {!order.expectedShipmentDate
                                            ? ""
                                            : order.expectedShipmentDate
                                                  .toDate()
                                                  .toLocaleDateString()}
                                    </TableCell>
                                    <TableCell align="right">
                                        {
                                            order.status === "CLOSED" ||
                                            order.status === "PAID"?
                                                <Button color={'primary'} variant={'contained'} onClick={()=>{
                                                    viewInvoice(order.id)
                                                }}>Download invoice</Button>
                                                : ""
                                        }
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

const mapStateToProps = ({ catalog }) => ({
    orders: catalog.retailerOrders,
});

export default withRouter(connect(mapStateToProps)(RetailerOrderList));
