import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AdminNewOrderComponent from "../../../components/admin/admin-new-order/admin-new-order.component";
import {firestore} from "../../../firebase/firebase.utils";
import {
    setAdminCurrent,
    setAdminFishCategories,
    setAdminFishTypes,
    setAdminRetailers,
    setAdminRetailerWarehouses,
    setAdminPurchases
} from "../../../redux/admin/admin.actions";

class AdminNewOrderPage extends React.Component {

    componentWillMount() {}

    componentDidMount() {
        const { setCurrent, setAdminRetailers, setAdminFishTypes, setAdminFishCategories, setAdminRetailerWarehouses, setAdminPurchases } = this.props;
        /*
        * date
        * retailer   - drp
        * fishType   - drp
        * FishCategory - drp
        * price
        * weight
        * payment status
        * */
        let current = [];
        setCurrent(current);
        firestore
            .collection("retailers")
            .get()
            .then((querySnapshot) => {
                let retailers = {};
                querySnapshot.forEach(function (doc) {
                    let retailer = doc.data();
                    retailers[retailer.id] = retailer;
                });
                setAdminRetailers(retailers);
            });

        // firestore
        //     .collection("fishTypes")
        //     .get()
        //     .then((querySnapshot) => {
        //         let fishTypes = {};
        //         querySnapshot.forEach(function (doc) {
        //             let fishType = doc.data();
        //             fishTypes[fishType.code] = fishType;
        //         });
        //         setAdminFishTypes(fishTypes);
        //     });
        firestore
            .collection("purchases")
            .where("status","==","LISTING")
            .get()
            .then((querySnapshot) => {
                let purchases = {};
                querySnapshot.forEach(function (doc) {
                    let purchase = doc.data();
                    purchases[purchase.id] = purchase;
                });
                setAdminPurchases(purchases);
            });
        setAdminRetailerWarehouses({});
    }

    componentWillUnmount() {

    }


    render() {
        return (
            <div>
                {/*<Typography*/}
                    {/*variant="subtitle1"*/}
                    {/*gutterBottom*/}
                    {/*style={{ textAlign: "center" }}*/}
                {/*>*/}
                    {/*Retailers*/}
                {/*</Typography>*/}
                <AdminNewOrderComponent />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setCurrent: (form) => dispatch(setAdminCurrent(form)),
    setAdminRetailers: (retailers) => dispatch(setAdminRetailers(retailers)),
    setAdminFishTypes: (retailers) => dispatch(setAdminFishTypes(retailers)),
    setAdminFishCategories: (retailers) => dispatch(setAdminFishCategories(retailers)),
    setAdminPurchases: (purchases) => dispatch(setAdminPurchases(purchases)),
    setAdminRetailerWarehouses: (retailers) => dispatch(setAdminRetailerWarehouses(retailers))
});

export default withRouter(connect(null, mapDispatchToProps)(AdminNewOrderPage));