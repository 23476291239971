import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
    setFishTypes,
    setRetailerConsignments,
    setRetailers,
} from "../../../redux/catalog/catalog.actions";
import { firestore } from "../../../firebase/firebase.utils";
import Typography from "@material-ui/core/Typography";
import AdminRetailerListComponent from "../../../components/admin/admin-retailers/admin-retailers.component";

class AdminRetailerListPage extends React.Component {
    unsubscribeSnapshot = null;

    componentWillMount() {}

    componentDidMount() {
        const { setRetailers } = this.props;
        this.unsubscribeSnapshot = firestore
            .collection("retailers")
            .onSnapshot((querySnapshot) => {
                let retailers = {};
                querySnapshot.forEach(function (doc) {
                    let retailer = doc.data();
                    retailers[retailer.id] = retailer;
                });
                setRetailers(retailers);
            });
    }

    componentWillUnmount() {
        this.unsubscribeSnapshot();
        const { setRetailers } = this.props;
        setRetailers([]);
    }

    render() {
        return (
            <div>
                <Typography
                    variant="subtitle1"
                    gutterBottom
                    style={{ textAlign: "center" }}
                >
                    Retailers
                </Typography>
                <AdminRetailerListComponent />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setRetailers: (retailers) => dispatch(setRetailers(retailers)),
});

export default withRouter(connect(null, mapDispatchToProps)(AdminRetailerListPage));
