import React from "react";
import "./order-consignment-list.scss";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { connect } from "react-redux";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: "36ch",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
    },
}));

const OrderConsignmentList = ({ order }) => {
    const classes = useStyles();
    return !order || !order.consignments ? (
        ""
    ) : (
        <Container style={{ maxWidth: 850 }}>
            <Paper className={classes.paper}>
                <TableContainer component={Paper}>
                    <Table
                        className={classes.table}
                        size="small"
                        aria-label="a dense table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Sno</TableCell>
                                <TableCell align="center">ID</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Fish</TableCell>
                                <TableCell align="center">
                                    Quantity Kg
                                </TableCell>
                                <TableCell align="center">
                                    Price per Kg
                                </TableCell>
                                <TableCell align="center">
                                    Total amount
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(order.consignments).map((key, i) => {
                                const consignment = order.consignments[key];
                                return (
                                    <TableRow key={consignment.id}>
                                        <TableCell component="th" scope="row">
                                            {i + 1}
                                        </TableCell>
                                        <TableCell align="center">
                                            {consignment.id}
                                        </TableCell>
                                        <TableCell align="center">
                                            {consignment.status}
                                        </TableCell>
                                        <TableCell align="center">{`${consignment.retailerProduct.fishType} (${consignment.retailerProduct.fishCategory})`}</TableCell>
                                        <TableCell align="right">
                                            {consignment.quantityMeasured}
                                        </TableCell>
                                        <TableCell align="right">
                                            {consignment.pricePerKg.toFixed(2)}
                                        </TableCell>
                                        <TableCell align="right">
                                            {(
                                                consignment.pricePerKg *
                                                consignment.quantityMeasured
                                            ).toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Container>
    );
};

const mapStateToProps = ({ common }) => ({
    order: common.current,
});

export default connect(mapStateToProps)(OrderConsignmentList);
