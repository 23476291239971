import React from "react";
import "./filter-group.component.scss";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
}));

const FilterGroup = ({ filter }) => {
    const classes = useStyles();
    return (
        <Container style={{maxWidth: 650}}>
            <Paper className={classes.paper}>
                <Typography
                    variant="h6"
                    gutterBottom
                    style={{ textAlign: "center" }}
                >
                    {filter.title}
                </Typography>
                <List component="nav" aria-label="secondary mailbox folders">
                    {filter.filters.map((filter) => {
                        return (
                            <Link key={filter.id} className={"filter-lint"} to={process.env.PUBLIC_URL + filter.url}>
                                <ListItem button>
                                    <ListItemText primary={filter.title} secondary={filter.description} />
                                </ListItem>
                            </Link>
                        );
                    })}
                </List>
            </Paper>
        </Container>
    );
};

export default FilterGroup;
