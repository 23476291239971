import React from "react";

const DevDbPage = () => {
  return (
    <div>
        <iframe src="http://167.86.104.221:9005" style={{height:'100vh', width:"100%"}}/>
    </div>
  );
};
//167.86.104.221:9005
export default DevDbPage;