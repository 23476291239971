import React from "react";
import "./fish-type-list.page";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {setFishTypes} from "../../../redux/catalog/catalog.actions";
import { firestore } from "../../../firebase/firebase.utils";
import Typography from "@material-ui/core/Typography";
import FishTypeListComponent from "../../../components/admin/fish-type-list/fish-type-list.component";

class FishTypeListPage extends React.Component {
    unsubscribeSnapshot = null;

    componentWillMount() {

    }

    componentDidMount() {
        const { setFishTypes } = this.props;
        this.unsubscribeSnapshot = firestore
            .collection("fishTypes")
            .where("status", "==", "ACTIVE")
            .onSnapshot((querySnapshot) => {
                let fishTypes = {};
                querySnapshot.forEach(function (doc) {
                    let fishType = doc.data();
                    fishTypes[fishType.code] = fishType;
                });
                setFishTypes(fishTypes);
            });
    }

    componentWillUnmount() {
        this.unsubscribeSnapshot();
        const { setFishTypes } = this.props;
        setFishTypes([]);
    }

    render() {
        return (
            <div>
                <Typography
                    variant="subtitle1"
                    gutterBottom
                    style={{ textAlign: "center" }}
                >
                    Fish types
                </Typography>
                <FishTypeListComponent />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setFishTypes: (fishTypes) =>
        dispatch(setFishTypes(fishTypes)),
});

export default withRouter(
    connect(null, mapDispatchToProps)(FishTypeListPage)
);
