import React from "react";
import "./retailer-consignment-detail.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import ConsignmentDetails from "../../../components/common/consignment/consignment-details/consignment-details.component";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import FishDetails from "../../../components/common/fish-details/fish-details.component";
import FishermanDetails from "../../../components/common/fisherman-details/fisherman-details.component";
import ConsignmentRemarks from "../../../components/common/consignment/consignment-remarks/consignment-remarks.component";
import ConsignmentTransactions from "../../../components/common/consignment/consignment-transactions/consignment-transactions.component";
import RetailerConsignmentCloseForm from "../../../components/common/consignment/retailer-consignment-close-form/retailer-consignment-close-form.component";
import { firestore } from "../../../firebase/firebase.utils";
import { setCurrent } from "../../../redux/common/common.actions";
import Typography from "@material-ui/core/Typography";

class RetailerConsignmentDetailPage extends React.Component {
    unsubscribeSnapshot = null;

    componentDidMount() {
        const { setCurrent } = this.props;
        const id = this.props.match.params.id;
        this.unsubscribeSnapshot = firestore
            .collection("retailerConsignments")
            .doc(id)
            .onSnapshot((docSnapshot) => {
                let consignment = docSnapshot.data();
                setCurrent(consignment);
            });
    }

    componentWillUnmount() {
        this.unsubscribeSnapshot();
        const { setCurrent } = this.props;
        setCurrent(null);
    }

    render() {
        return (
            <Container>
                <Typography
                    variant="subtitle1"
                    gutterBottom
                    style={{ textAlign: "center" }}
                >
                    Shipment: #{this.props.match.params.id}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <RetailerConsignmentCloseForm />
                    </Grid>
                    <Grid item xs={12}>
                        <ConsignmentDetails />
                    </Grid>
                    <Grid item xs={12}>
                        <FishDetails />
                    </Grid>
                    <Grid item xs={12}>
                        <FishermanDetails />
                    </Grid>
                    <Grid item xs={12}>
                        <ConsignmentRemarks />
                    </Grid>
                    <Grid item xs={12}>
                        <ConsignmentTransactions />
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setCurrent: (consignment) => dispatch(setCurrent(consignment)),
});

export default withRouter(
    connect(null, mapDispatchToProps)(RetailerConsignmentDetailPage)
);
