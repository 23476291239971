import React from "react";
import "./retailer-consignment-list.scss";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { connect } from "react-redux";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import { Link, withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: "36ch",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
    },
}));

const RetailerConsignmentList = ({ consignments, history }) => {
    const classes = useStyles();
    return (
        <Container>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Sno</TableCell>
                            <TableCell align="center">ID</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">Fish</TableCell>
                            <TableCell align="center">
                                Quantity(Dispatched) Kg
                            </TableCell>
                            <TableCell align="center">
                                Quantity(Measured) Kg
                            </TableCell>
                            <TableCell align="center">Price per Kg</TableCell>
                            <TableCell align="center">Total amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(consignments).map((key, i) => {
                            const consignment = consignments[key];
                            return (
                                <TableRow
                                    key={consignment.id}
                                    hover={true}
                                    className={"clickable-row"}
                                    onClick={() => {
                                        history.push(
                                            `${process.env.PUBLIC_URL}/retailerConsignment/${consignment.id}`
                                        );
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {i + 1}
                                    </TableCell>
                                    <TableCell align="center">
                                        {consignment.id}
                                    </TableCell>
                                    <TableCell align="center">
                                        {consignment.status}
                                    </TableCell>
                                    <TableCell align="center">{`${consignment.retailerProduct.fishType} (${consignment.retailerProduct.fishCategory})`}</TableCell>
                                    <TableCell align="right">
                                        {consignment.quantity}
                                    </TableCell>
                                    <TableCell align="right">
                                        {consignment.quantityMeasured}
                                    </TableCell>
                                    <TableCell align="right">
                                        {consignment.pricePerKg.toFixed(2)}
                                    </TableCell>
                                    <TableCell align="right">
                                        {(
                                            consignment.pricePerKg *
                                            consignment.quantityMeasured
                                        ).toFixed(2)}
                                    </TableCell>
                                    {/*<TableCell align="right">*/}
                                    {/*<Link*/}
                                    {/*to={`/retailerConsignment/${consignment.id}`}*/}
                                    {/*>*/}
                                    {/*<NavigateNextIcon />*/}
                                    {/*</Link>*/}
                                    {/*</TableCell>*/}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

const mapStateToProps = ({ catalog }) => ({
    consignments: catalog.retailerConsignments,
});

export default withRouter(connect(mapStateToProps)(RetailerConsignmentList));
