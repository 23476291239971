
export const setRetailerConsignments = (consignments) => {
    return {
        type: 'SET_RETAILER_CONSIGNMENTS',
        payload: consignments,
    };
};

export const setRetailerOrders = (orders) => {
    return {
        type: 'SET_RETAILER_ORDERS',
        payload: orders,
    };
};

export const setFishTypes = (fishTypes) => {
    return {
        type: 'SET_FISH_TYPES',
        payload: fishTypes
    }
};

export const setRetailers = (retailers) => {
    return {
        type: 'SET_RETAILERS',
        payload: retailers
    }
};