import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AdminNewCustomerOrderComponent from "../../../components/admin/admin-b2c-new-order/admin-b2c-new-order.component";
import {firestore} from "../../../firebase/firebase.utils";
import {
    setAdminCurrent,
    setAdminStates,
    setAdminFishTypes, setAdminCustomerProducts
} from "../../../redux/admin/admin.actions";

class AdminNewCustomerOrderPage extends React.Component {

    componentWillMount() {}

    componentDidMount() {
        const { setAdminCurrent, setAdminStates, setAdminFishTypes, setAdminCustomerProducts } = this.props;
        let current = [];
        setAdminCurrent(current);
        // firestore
        //     .collection("states")
        //     .where("country", "==", "India")
        //     .get()
        //     .then((querySnapshot) => {
        //         let states = {};
        //         querySnapshot.forEach(function (doc) {
        //             let state = doc.data();
        //             states[state.code] = state;
        //         });
        //         setAdminStates(states);
        //     });

        firestore
            .collection("fishTypes")
            .get()
            .then((querySnapshot) => {
                let fishTypes = {};
                querySnapshot.forEach(function (doc) {
                    let fishType = doc.data();
                    fishTypes[fishType.code] = fishType;
                });
                setAdminFishTypes(fishTypes);
            });

        firestore
            .collection("customerProducts")
            .where("status","==","LISTED")
            .get()
            .then((querySnapshot) => {
                let products = {};
                querySnapshot.forEach(function (doc) {
                    let product = doc.data();
                    products[product.id] = product;
                });
                setAdminCustomerProducts(products);
            });
    }

    componentWillUnmount() {

    }


    render() {
        return (
            <div>
                <AdminNewCustomerOrderComponent />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setAdminCurrent: (form) => dispatch(setAdminCurrent(form)),
    setAdminCustomerProducts: (purchases) => dispatch(setAdminCustomerProducts(purchases)),
    setAdminStates: (states) => dispatch(setAdminStates(states)),
    setAdminFishTypes: (fishTypes) => dispatch(setAdminFishTypes(fishTypes))
});

export default withRouter(connect(null, mapDispatchToProps)(AdminNewCustomerOrderPage));