import React from "react";
import "./retailer-order-filter.scss";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRetailerOrders } from "../../../redux/catalog/catalog.actions";
import { firestore } from "../../../firebase/firebase.utils";
import RetailerOrderList from "../../../components/common/retailer-order/retailer-order-list/retailer-order-list.component";
import Typography from "@material-ui/core/Typography";

class RetailerOrderFilterPage extends React.Component {
    unsubscribeSnapshot = null;
    status = "";

    componentWillMount() {
        const query = new URLSearchParams(this.props.location.search);
        this.status = query.get("status");
        if(!this.status || this.status==="")
            this.status = "ALL";
        console.log("q", this.status);
    }

    componentDidMount() {
        const { setRetailerOrders } = this.props;
        let query = firestore
            .collection("retailerOrders")
            .orderBy("createdOn", "desc");
        if (this.status!=="ALL"){
            query = firestore
                .collection("retailerOrders")
                .where("status", "==", this.status)
        }
        this.unsubscribeSnapshot = query.onSnapshot((querySnapshot) => {
                let orders = {};
                querySnapshot.forEach(function (doc) {
                    let order = doc.data();
                    orders[order.id] = order;
                });
                setRetailerOrders(orders);
            });
    }

    componentWillUnmount() {
        this.unsubscribeSnapshot();
        const { setRetailerOrders } = this.props;
        setRetailerOrders([]);
    }

    render() {
        return (
            <div>
                <Typography
                    variant="subtitle1"
                    gutterBottom
                    style={{ textAlign: "center" }}
                >
                    Retailer order: {this.status}
                </Typography>
                <RetailerOrderList />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setRetailerOrders: (orders) => dispatch(setRetailerOrders(orders)),
});

export default withRouter(
    connect(null, mapDispatchToProps)(RetailerOrderFilterPage)
);
