import React from "react";
import "./consignment-remarks.scss";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import {connect} from "react-redux";

const useStyles = makeStyles((theme)=>({
    table: {

    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    root: {
        width: '100%',
        maxWidth: '36ch',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
}));

const ConsignmentRemarks = ({ consignment }) => {
    const classes = useStyles();

    let rows = [];
    if(consignment)
        rows = consignment.remarks;

    return !consignment?(""):(
        <Container style={{ maxWidth: 650 }}>
            <Paper className={classes.paper}>
                <Typography variant={"h6"}>Remarks</Typography>
                <List className={classes.root}>
                    {
                        rows.map((row)=>(
                            <ListItem key={row.id} alignItems="flex-start">
                                <ListItemText
                                    primary={['ACCEPTED','REJECTED'].indexOf(row.nextStaus)!==-1?(row.retailerName):(row.agentName)}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.inline}
                                                color="textPrimary"
                                            >
                                                {row.content}
                                            </Typography>
                                            {` (${row.prevStatus} -> ${row.nextStatus})`}
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        ))
                    }
                    <Divider variant="inset" component="li" />
                </List>
            </Paper>
        </Container>
    );
};

const mapStateToProps = ({common})=>({
    consignment: common.current
});

export default withRouter(connect(mapStateToProps)(ConsignmentRemarks));
