import React from "react";
import "./sign-in.page.scss";
import SignIn from "../../components/sign-in/sign-in.component";

const SignInPage = () => {
  return (
    <div>
      <SignIn />
    </div>
  );
};
export default SignInPage;