import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import { Provider } from 'react-redux';
import store from './redux/store';

ReactDOM.render(
  <Provider store={store}>
      <React.StrictMode>
          <BrowserRouter  basename={'/'}>
              <App />
          </BrowserRouter>
      </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);