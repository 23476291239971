import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
    apiKey: "AIzaSyBFl7d1mGyaTU0PpG71nFPRkxOME8JtT_Y",
    authDomain: "ofish-53c9f.firebaseapp.com",
    databaseURL: "https://ofish-53c9f.firebaseio.com",
    projectId: "ofish-53c9f",
    storageBucket: "ofish-53c9f.appspot.com",
    messagingSenderId: "727202514749",
    appId: "1:727202514749:web:18e413bb9ead2a3a842754",
    measurementId: "G-195GWLMSSS"
};
firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
// firestore.settings({
//     host: "167.86.104.221:9002",
//     ssl: false
// });
// export firestore

// const googleProvider = new firebase.auth.GoogleAuthProvider();
// googleProvider.setCustomParameters({ prompt: 'select_account' });
// export const signInWithGoogle = () => auth.signInWithPopup(googleProvider);

export default firebase;