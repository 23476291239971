import React from "react";
import "./header.component.scss";

import { auth } from "../../firebase/firebase.utils";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import WorkIcon from "@material-ui/icons/Work";
import { makeStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Avatar from "@material-ui/core/Avatar";
import { AccountCircle } from "@material-ui/icons";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
}));

const mapStateToProps = (state) => ({
    currentUser: state.user.currentUser,
});

const Header = ({ currentUser }) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const open2 = Boolean(anchorEl2);
    const handleMenu2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    return (
        <div className={classes.root}>
            <AppBar className={"header"} position="fixed">
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        <Link className={"nav-link"} to={`${process.env.PUBLIC_URL}/`}>
                            O-Fish
                        </Link>
                    </Typography>
                    {currentUser ? (
                        <div>
                            <Button color="inherit" disabled>
                                <div className={"nav-link"}>
                                    Signed in as: {currentUser.profile.name}
                                </div>
                            </Button>

                            {/*Admin controls*/}
                            {currentUser &&
                            currentUser.role === "ADMIN" ? (
                                <IconButton
                                    color="inherit"
                                    aria-controls="menu-appbar2"
                                    aria-haspopup="true"
                                    onClick={handleMenu2}
                                >
                                    <SettingsApplicationsIcon />
                                </IconButton>
                            ) : (
                                ""
                            )}

                            {currentUser &&
                            currentUser.role === "ADMIN" ? (
                                <Menu
                                    id="menu-appbar2"
                                    anchorEl={anchorEl2}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    open={open2}
                                    onClose={handleClose2}
                                >
                                    <MenuItem onClick={handleClose2}>
                                        <Link
                                            className={"nav-link-menu"}
                                            to={`${process.env.PUBLIC_URL}/fishTypes`}
                                        >
                                            Fish types
                                        </Link>
                                    </MenuItem>
                                </Menu>
                            ) : (
                                ""
                            )}

                            {currentUser &&
                            (currentUser.role === "NUMER8_ADMIN" || currentUser.role === "ADMIN") ? (
                                <Link className={"nav-link"} to={`${process.env.PUBLIC_URL}/`}>
                                    <IconButton color="inherit">
                                        <WorkIcon />
                                    </IconButton>
                                </Link>
                            ) : (
                                ""
                            )}

                            {/*Admin controls*/}

                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleClose}>
                                    My profile
                                </MenuItem>
                                {
                                    currentUser
                                    && (currentUser.id === "Ip6VKvQhCbPhFBLMXIxVkSiy3pE2"
                                    || currentUser.id === "3P0U7bkFVNZV2TjUPFZbS4FdHNJ2")?
                                        (<Link className={"nav-link-black"} to={`${process.env.PUBLIC_URL}/dev_db`}>
                                            <MenuItem onClick={handleClose}>
                                                Database(Dev)
                                            </MenuItem>
                                        </Link>):""
                                }
                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        auth.signOut();
                                    }}
                                >
                                    Sign Out
                                </MenuItem>
                            </Menu>

                            {/*<IconButton*/}
                            {/*onClick={() => {*/}
                            {/*auth.signOut();*/}
                            {/*}}*/}
                            {/*color="inherit"*/}
                            {/*>*/}
                            {/*<ExitToAppIcon />*/}
                            {/*</IconButton>*/}
                        </div>
                    ) : (
                        <div>
                            <Button color="inherit">
                                <Link className={"nav-link"} to={`${process.env.PUBLIC_URL}/SignIn`}>
                                    Sign in
                                </Link>
                            </Button>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default connect(mapStateToProps)(Header);
