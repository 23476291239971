import { combineReducers } from 'redux';

import userReducer from './user/user.reducer';
import catalogReducer from './catalog/catalog.reducer';
import commonReducer from './common/common.reducer';
import adminReducer from './admin/admin.reducer';

export default combineReducers({
    user: userReducer,
    catalog: catalogReducer,
    common: commonReducer,
    admin: adminReducer
})