import React from "react";
import Container from "@material-ui/core/Container";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { firestore } from "../../../../firebase/firebase.utils";
import firebase from "../../../../firebase/firebase.utils";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
}));

const RetailerOrderForm = ({ order }) => {
    const classes = useStyles();

    let amount = 0;
    if (order && order.consignments) {
        Object.keys(order.consignments).map((key) => {
            amount +=
                order.consignments[key].quantityMeasured *
                order.consignments[key].pricePerKg;
        });
    }

    const confirmOrderPayment = async () => {
        await firestore.collection("retailerOrders").doc(order.id).update({
            status: "PAID",
            updatedOn: firebase.firestore.Timestamp.fromDate(new Date())
        });
        await firestore.collection("retailerInvoices").where("orderId","==",order.id).get()
            .then(async querySnapshot => {
                await querySnapshot.forEach(async doc => {
                    let invoice = doc.data();
                    let updates = {};
                    updates["status"] = "PAID";
                    updates["updatedOn"] = firebase.firestore.Timestamp.fromDate(new Date());
                    await firestore.collection("retailerInvoices").doc(invoice.id).update(updates);
                })
            })
    };

    return !order || !order.consignments? (
        ""
    ) : order.status !== "CLOSED" ? (
        ""
    ) : (
        <Container style={{ maxWidth: 850 }}>
            <Paper className={classes.paper}>
                <Typography variant={"h6"}>Confirm order payment</Typography>
                <Box>
                    <TextField
                        disabled
                        label="Amount to be paid"
                        defaultValue={amount.toFixed(2)}
                        variant="outlined"
                        fullWidth
                        style={{ marginTop: 25 }}
                    />
                    <br />
                    <Box style={{ textAlign: "right" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginTop: 25 }}
                            onClick={() => {
                                confirmOrderPayment();
                            }}
                        >
                            Confirm payment
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Container>
    );
};
const mapStateToProps = ({ common }) => ({
    order: common.current,
});

export default connect(mapStateToProps)(RetailerOrderForm);
