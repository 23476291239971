import React from 'react';
import './sign-in.component.scss';
import * as firebaseui from 'firebaseui';
import firebase from '../../firebase/firebase.utils';

import {auth} from '../../firebase/firebase.utils';

const ui = new firebaseui.auth.AuthUI(auth);

class SignIn extends React.Component{
    constructor(props){
        super(props);
    }

    componentDidMount() {
        ui.start('#firebaseui-auth-container', {
            signInOptions: [
                {
                    provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                    defaultCountry: 'IN'
                },
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            ],
            signInFlow: 'popup',
            callbacks: {
                signInSuccessWithAuthResult(authResult, redirectUrl) {
                    return false;
                }
            },
        });
    }

    render() {
        return (<div>
            <div id="firebaseui-auth-container"></div>
        </div>);
    }
}

export default SignIn;