
const INITIAL_STATE = {
    retailerConsignments: {},
    retailerOrders: {},
    fishTypes: {},
    retailers: {}
};

const catalogReducer = (state = INITIAL_STATE, action) => {
    const strategy = strategies[action.type] ?? strategies.__default__;
    return strategy(state, action);
};

const strategies = {
    SET_RETAILER_CONSIGNMENTS: setRetailerConsignments,
    SET_RETAILER_ORDERS: setRetailerOrders,
    SET_FISH_TYPES: setFishTypes,
    SET_RETAILERS: setRetailers,
    __default__: state => state
};
function setRetailerOrders(state, action) {
    return {
        ...state,
        retailerOrders: action.payload
    };
}
function setRetailerConsignments(state, action) {
    return {
        ...state,
        retailerConsignments: action.payload
    };
}
function setFishTypes(state, action) {
    return {
        ...state,
        fishTypes: action.payload
    };
}
function setRetailers(state, action) {
    return {
        ...state,
        retailers: action.payload
    };
}

export default catalogReducer;