import React from "react";
import "./App.scss";
import { Switch, Route, Redirect } from "react-router-dom";
import HomePage from "./pages/home/home.page";
import SignInPage from "./pages/sign-in/sign-in.page";
import { auth } from "./firebase/firebase.utils";
import Header from "./components/header/header.component";
import { firestore } from "./firebase/firebase.utils";
import { connect } from "react-redux";
import { setCurrentUser } from "./redux/user/user.actions";
import RetailerConsignmentDetailPage from "./pages/common/retailer-consignment-detail/retailer-consignment-detail.page";
import RetailerConsignmentFilter from "./pages/common/retailer-consignment-filter/retailer-consignment-filter.page";
import Box from "@material-ui/core/Box";
import RetailerOrderFilterPage from "./pages/common/retailer-order-filter/retailer-order-filter.page";
import RetailerOrderDetailPage from "./pages/common/retailer-order-detail/retailer-order-detail.page";
import AdminRetailerListPage from "./pages/admin/admin-retailers/retailers-list.page";
import AdminRetailerEditPage from "./pages/admin/admin-retailer-edit/admin-retailer-edit.page";
import AdminRetailerNewPage from "./pages/admin/admin-retailer-new/admin-retailer-new.page";
import AdminNewOrderPage from "./pages/admin/admin-new-order/admin-new-order.page";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import FishTypeListPage from "./pages/admin/fish-type-list/fish-type-list.page";
import FishTypeEditPage from "./pages/admin/fish-type-edit/fish-type-edit.page";
import FishTypeNewPage from "./pages/admin/fish-type-new/fish-type-new.page";
import RetailerOrderList from "./components/common/retailer-order/retailer-order-list/retailer-order-list.component";
import AdminNewCustomerOrderPage from "./pages/admin/admin-b2c-new-order/admin-new-customer-order.page";
import DevDbPage from "./pages/dev-db.page";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#344955",
        },
        secondary: {
            main: "#F9AA33",
        },
    },
});

class App extends React.Component {
    unSubscribeFromAuth = null;

    componentDidMount() {
        const { setCurrentUser } = this.props;

        this.unSubscribeFromAuth = auth.onAuthStateChanged(async (user) => {
            if (user) {
                const userMetaSnap = await firestore
                    .collection("usersMetadata")
                    .doc(user.uid)
                    .get();
                const userMetadata = userMetaSnap.data();

                if(userMetadata){
                    const collectionName = userMetadata.role === 'NUMER8_ADMIN'?'numer8Admins' || userMetadata.userType === 'NUMER8_ADMIN':
                        userMetadata.role === 'ADMIN' || userMetadata.userType === 'ADMIN'?'admins':
                            userMetadata.role === 'RETAILER' || userMetadata.userType === 'RETAILER'?'retailers':
                                userMetadata.role === 'AGENT' || userMetadata.userType === 'AGENT'?'agents': 'customers';
                    const profileSnap = await firestore
                        .collection(collectionName)
                        .doc(user.uid)
                        .get();
                    const profileData = profileSnap.data();
                    if(profileData){
                        setCurrentUser({
                            id: user.uid,
                            role: userMetadata.role,
                            profile: profileData
                        });
                    }else{
                        auth.signOut();
                        setCurrentUser(null);
                    }
                }else{
                    auth.signOut();
                    setCurrentUser(null);
                }
            } else {
                setCurrentUser(null);
            }
        });
    }

    componentWillUnmount() {
        this.unSubscribeFromAuth();
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <div>
                    <Header />
                    <div className={"test"} style={{ marginTop: 80 }}>
                        <Switch>
                            {/*Common Routes*/}
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/`}
                                render={() =>
                                    <HomePage />
                                }
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/SignIn`}
                                render={() =>
                                    this.props.currentUser ? (
                                        <Redirect to={`${process.env.PUBLIC_URL}/`} />
                                    ) : (
                                        <SignInPage />
                                    )
                                }
                            />
                            {/*Admin Routes*/}
                            <Route
                                path={`${process.env.PUBLIC_URL}/fishTypes`}
                                render={() =>
                                    this.props.currentUser && this.props.currentUser.role === 'ADMIN' ? (
                                        <FishTypeListPage />
                                    ) : (
                                        <Redirect to={`${process.env.PUBLIC_URL}/`} />
                                    )
                                }
                            />
                            <Route
                                path={`${process.env.PUBLIC_URL}/fishType/edit/:id`}
                                render={() =>
                                    this.props.currentUser && this.props.currentUser.role === 'ADMIN' ? (
                                        <FishTypeEditPage />
                                    ) : (
                                        <Redirect to={`${process.env.PUBLIC_URL}/`} />
                                    )
                                }
                            />

                            <Route
                                path={`${process.env.PUBLIC_URL}/fishType/new`}
                                render={() =>
                                    this.props.currentUser && (this.props.currentUser.role === 'ADMIN') ? (
                                        <FishTypeNewPage />
                                    ) : (
                                        <Redirect to={`${process.env.PUBLIC_URL}/`} />
                                    )
                                }
                            />
                            <Route
                                path={`${process.env.PUBLIC_URL}/retailerConsignments`}
                                render={() =>
                                    this.props.currentUser && (this.props.currentUser.role === 'NUMER8_ADMIN'
                                        || this.props.currentUser.role === 'ADMIN') ? (
                                        <RetailerConsignmentFilter />
                                    ) : (
                                        <Redirect to={`${process.env.PUBLIC_URL}/`} />
                                    )
                                }
                            />
                            <Route
                                path={`${process.env.PUBLIC_URL}/retailerOrders`}
                                render={() =>
                                    this.props.currentUser && (this.props.currentUser.role === 'NUMER8_ADMIN'
                                        || this.props.currentUser.role === 'ADMIN') ? (
                                        <RetailerOrderFilterPage />
                                    ) : (
                                        <Redirect to={`${process.env.PUBLIC_URL}/`} />
                                    )
                                }
                            />
                            <Route
                                path={`${process.env.PUBLIC_URL}/retailerConsignment/:id`}
                                render={() =>
                                    this.props.currentUser && (this.props.currentUser.role === 'NUMER8_ADMIN'
                                        || this.props.currentUser.role === 'ADMIN') ? (
                                        <RetailerConsignmentDetailPage />
                                    ) : (
                                        <Redirect to={`${process.env.PUBLIC_URL}/`} />
                                    )
                                }
                            />
                            <Route
                                path={`${process.env.PUBLIC_URL}/retailerOrder/:id`}
                                render={() =>
                                    this.props.currentUser && (this.props.currentUser.role === 'NUMER8_ADMIN'
                                        || this.props.currentUser.role === 'ADMIN') ? (
                                        <RetailerOrderDetailPage />
                                    ) : (
                                        <Redirect to={`${process.env.PUBLIC_URL}/`} />
                                    )
                                }
                            />
                            <Route
                                path={`${process.env.PUBLIC_URL}/admin/retailers`}
                                render={() =>
                                    this.props.currentUser && (this.props.currentUser.role === 'ADMIN') ? (
                                        <AdminRetailerListPage />
                                    ) : (
                                        <Redirect to={`${process.env.PUBLIC_URL}/`} />
                                    )
                                }
                            />
                            <Route
                                path={`${process.env.PUBLIC_URL}/admin/b2b-orders`}
                                render={() =>
                                    this.props.currentUser && (this.props.currentUser.role === 'ADMIN') ? (
                                        <RetailerOrderFilterPage />
                                    ) : (
                                        <Redirect to={`${process.env.PUBLIC_URL}/`} />
                                    )
                                }
                            />
                            <Route
                                path={`${process.env.PUBLIC_URL}/admin/retailer/edit/:id`}
                                render={() =>
                                    this.props.currentUser && (this.props.currentUser.role === 'ADMIN') ? (
                                        <AdminRetailerEditPage />
                                    ) : (
                                        <Redirect to={`${process.env.PUBLIC_URL}/`} />
                                    )
                                }
                            />
                            <Route
                                path={`${process.env.PUBLIC_URL}/admin/retailer/new`}
                                render={() =>
                                    this.props.currentUser && (this.props.currentUser.role === 'ADMIN') ? (
                                        <AdminRetailerNewPage />
                                    ) : (
                                        <Redirect to={`${process.env.PUBLIC_URL}/`} />
                                    )
                                }
                            />
                            <Route
                                path={`${process.env.PUBLIC_URL}/admin/b2b-order/new`}
                                render={() =>
                                    this.props.currentUser && (this.props.currentUser.role === 'ADMIN') ? (
                                        <AdminNewOrderPage />
                                    ) : (
                                        <Redirect to={`${process.env.PUBLIC_URL}/`} />
                                    )
                                }
                            />
                            <Route
                                path={`${process.env.PUBLIC_URL}/admin/b2c-order/new`}
                                render={() =>
                                    this.props.currentUser && (this.props.currentUser.role === 'ADMIN') ? (
                                        <AdminNewCustomerOrderPage />
                                    ) : (
                                        <Redirect to={`${process.env.PUBLIC_URL}/`} />
                                    )
                                }
                            />
                            <Route
                                path={`${process.env.PUBLIC_URL}/dev_db`}
                                render={() =>
                                    this.props.currentUser && (this.props.currentUser.id === "Ip6VKvQhCbPhFBLMXIxVkSiy3pE2"
                                        || this.props.currentUser.id === "3P0U7bkFVNZV2TjUPFZbS4FdHNJ2") ? (
                                        <DevDbPage />
                                    ) : (
                                        <Redirect to={`${process.env.PUBLIC_URL}/`} />
                                    )
                                }
                            />
                        </Switch>
                    </div>
                </div>
            </ThemeProvider>
        );
    }
}

const mapStateToProps = ({ user }) => ({
    currentUser: user.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
