import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {setAdminCurrent} from "../../../redux/admin/admin.actions";
import {firestore} from "../../../firebase/firebase.utils";
import firebase from "../../../firebase/firebase.utils";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: "36ch",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const AdminRetailerEditComponent = ({retailer, countries, states, languages, retailerTypes, setCurrent, history}) => {
    const classes = useStyles();

    let statesSelected = [];
    if(retailer!==null && states!==null){
        Object.keys(states).map(key=>{
            let state = states[key];
            if(state.country===retailer.country)
                statesSelected[state.code] = state;
        });
    }

    const handleChange = (e, attr)=>{
        const value = e.target.value;
        let retailerNew = {...retailer};
        retailerNew[attr] = value;
        if(attr==="country" || attr==="state")
            retailerNew["address"][attr] = value;
        setCurrent(retailerNew);
    };
    const handleAddressChange = (e, attr)=>{
        const value = e.target.value;
        let retailerNew = {...retailer};
        retailerNew["address"][attr] = value;
        setCurrent(retailerNew);
    };

    const updateRetailer = async () => {
        let retailerNew = {...retailer};
        retailerNew.updatedOn = firebase.firestore.Timestamp.fromDate(new Date());
        await firestore
            .collection("retailers")
            .doc(retailer.id)
            .set(retailerNew);
        history.push(`${process.env.PUBLIC_URL}/admin/retailers`)
    };

    return retailer !==null && retailer.address &&countries!==null&&states!==null&&languages!==null&&retailerTypes!==null?(
        <Container>
            <Paper className={classes.paper}>
                <Typography variant={"h6"}>Edit</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Name"
                            value={retailer.name}
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 25 }}
                            onChange={e=>{
                                handleChange(e, "name")
                            }}
                        />
                        <Select
                            fullWidth
                            variant="outlined"
                            value={retailer.retailerType}
                            style={{ textAlign: "left", marginTop: 25 }}
                            onChange={e=>{
                                handleChange(e, "retailerType")
                            }}
                        >
                            {
                                Object.keys(retailerTypes).map(key=>{
                                    let retailerType = retailerTypes[key];
                                    return (<MenuItem key={retailerType.code} value={retailerType.code}>{retailerType.name}</MenuItem>)
                                })
                            }
                        </Select>
                        <Select
                            fullWidth
                            variant="outlined"
                            value={retailer.language}
                            style={{ textAlign: "left", marginTop: 25 }}
                            onChange={e=>{
                                handleChange(e, "language")
                            }}
                        >
                            {
                                Object.keys(languages).map(key=>{
                                    let language = languages[key];
                                    return (<MenuItem key={language.code} value={language.code}>{language.name}</MenuItem>)
                                })
                            }
                        </Select>
                        <Select
                            fullWidth
                            variant="outlined"
                            value={retailer.country}
                            style={{ textAlign: "left", marginTop: 25 }}
                            onChange={e=>{
                                handleChange(e, "country")
                            }}
                        >
                            {
                                Object.keys(countries).map(key=>{
                                    let country = countries[key];
                                    return (<MenuItem key={country.code} value={country.code}>{country.name}</MenuItem>)
                                })
                            }
                        </Select>
                        <TextField
                            label="GST No"
                            value={retailer.gstNo}
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 25 }}
                            onChange={e=>{
                                handleChange(e, "gstNo")
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Street address"
                            value={retailer.address.streetAddress}
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 25 }}
                            onChange={e=>{
                                handleAddressChange(e, "streetAddress")
                            }}
                        />
                        <TextField
                            label="City"
                            value={retailer.address.city}
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 25 }}
                            onChange={e=>{
                                handleAddressChange(e, "city")
                            }}
                        />
                        <TextField
                            label="Pincode"
                            value={retailer.address.pincode}
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 25 }}
                            onChange={e=>{
                                handleAddressChange(e, "pincode")
                            }}
                        />
                        <Select
                            fullWidth
                            variant="outlined"
                            value={retailer.state}
                            style={{ textAlign: "left", marginTop: 25 }}
                            onChange={e=>{
                                handleChange(e, "state")
                            }}
                        >
                            {
                                Object.keys(statesSelected).map(key=>{
                                    let state = statesSelected[key];
                                    return (<MenuItem key={state.code} value={state.code}>{state.name}</MenuItem>)
                                })
                            }
                        </Select>
                        <Grid container spacing={1}>
                            <Grid xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ marginTop: 25 }}
                                    onClick={() => {
                                        updateRetailer();
                                    }}
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    ): "";
};

const mapStateToProps = ({ admin }) => ({
    retailer: admin.current,
    countries: admin.countries,
    states: admin.states,
    languages: admin.languages,
    retailerTypes: admin.retailerTypes
});
const mapDispatchToProps = (dispatch) => ({
    setCurrent: (retailer) => dispatch(setAdminCurrent(retailer))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminRetailerEditComponent));
