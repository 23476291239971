import React from "react";
import "./home.page.scss";
import N8AdminWork from "../../components/n8-admin-works/n8-admin-works.component";
import AdminWork from "../../components/admin/admin-works/admin-works.component";
import { connect } from "react-redux";

const HomePage = ({ currentUser }) => {
    return (
        <div>
            {currentUser && currentUser.role === "NUMER8_ADMIN" ? (
                <N8AdminWork />
            ) : currentUser && currentUser.role === "ADMIN" ? (
                <AdminWork />
            ) : (
                "Coming soon"
            )}
        </div>
    );
};

const mapStateToProps = ({ user }) => {
    return {
        currentUser: user.currentUser,
    };
};

export default connect(mapStateToProps)(HomePage);
