import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Container from "@material-ui/core/Container";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {makeStyles} from "@material-ui/core/styles";
import Button from "../admin-retailer-edit/admin-retailer-edit.component";
import {firestore} from "../../../firebase/firebase.utils";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: "36ch",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
    },
}));

const AdminRetailerListComponent = ({retailers, history}) => {
    const classes = useStyles();

    const deleteRetailer = async (id) => {
        await firestore
            .collection("retailers")
            .doc(id)
            .delete();
        history.push(`${process.env.PUBLIC_URL}/admin/retailers`)
    };

    return (
        <Container>
            <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Sno</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Address</TableCell>
                            <TableCell align="left">Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(retailers).map((key, i) => {
                            const retailer = retailers[key];
                            return (
                                <TableRow
                                    key={`${retailer.id}`}>
                                    <TableCell component="th" scope="row">
                                        {i + 1}
                                    </TableCell>
                                    <TableCell component="th" scope="row"
                                               hover={true}
                                               className={"clickable-row"}
                                               onClick={()=>{
                                                   history.push(`${process.env.PUBLIC_URL}/admin/retailer/edit/${retailer.id}`)
                                               }}>
                                        {retailer.name}
                                    </TableCell>
                                    <TableCell align="left">
                                        {`${retailer.address.streetAddress}, ${retailer.address.city}, ${retailer.address.state} - ${retailer.address.pincode}`}
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => {
                                                deleteRetailer(retailer.id);
                                            }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

const mapStateToProps = ({ catalog }) => ({
    retailers: catalog.retailers,
});

export default withRouter(connect(mapStateToProps)(AdminRetailerListComponent));
