import React from "react";
import "./retailer-order-detail.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { firestore } from "../../../firebase/firebase.utils";
import { setCurrent } from "../../../redux/common/common.actions";
import OrderDetails from "../../../components/common/retailer-order/order-details/order-details.component";
import OrderConsignmentList from "../../../components/common/retailer-order/order-consignment-list/order-consignment-list.component";
import RetailerOrderForm from "../../../components/common/retailer-order/retailer-order-form/retailer-order-form.component";
import Typography from "@material-ui/core/Typography";

class RetailerOrderDetailPage extends React.Component {
    unsubscribeSnapshot = null;
    unsubscribeConsignmentsSnapshot = null;

    componentDidMount() {
        const { setCurrent } = this.props;
        const id = this.props.match.params.id;
        this.unsubscribeSnapshot = firestore
            .collection("retailerOrders")
            .doc(id)
            .onSnapshot((docSnapshot) => {
                let order = docSnapshot.data();
                this.unsubscribeConsignmentsSnapshot = firestore
                    .collection("retailerConsignments")
                    .where("orderId", "==", id)
                    .onSnapshot(async (querySnapshot) => {
                        let consignments = {};
                        querySnapshot.forEach(function (doc) {
                            let consignment = doc.data();
                            consignments[consignment.id] = consignment;
                        });
                        order["consignments"] = consignments;
                        let warehouseDoc = await firestore
                            .collection("retailerWarehouses")
                            .doc(order.warehouseId)
                            .get();
                        if (warehouseDoc)
                            order["warehouse"] = warehouseDoc.data();
                        setCurrent(order);
                    });
            });
    }

    componentWillUnmount() {
        this.unsubscribeSnapshot();
        if (this.unsubscribeConsignmentsSnapshot)
            this.unsubscribeConsignmentsSnapshot();
        const { setCurrent } = this.props;
        setCurrent(null);
    }

    render() {
        const markOrderPaid = () => {};
        return (
            <Container>
                <Typography
                    variant="subtitle1"
                    gutterBottom
                    style={{ textAlign: "center" }}
                >
                    Order: #{this.props.match.params.id}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <RetailerOrderForm />
                    </Grid>
                    <Grid item xs={12}>
                        <OrderDetails />
                    </Grid>
                    <Grid item xs={12}>
                        <OrderConsignmentList />
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setCurrent: (consignment) => dispatch(setCurrent(consignment)),
});

export default withRouter(
    connect(null, mapDispatchToProps)(RetailerOrderDetailPage)
);
