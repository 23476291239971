import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from "@material-ui/core/Typography";
import { firestore }  from "../../../firebase/firebase.utils"
import {setAdminCurrent} from "../../../redux/admin/admin.actions";
import firebase from "../../../firebase/firebase.utils";

const FishTypeNewComponent = ({ fishType, setCurrent , history, currentUser}) => {
    // let { fishType } = fishType;
    let isFormValid = false;
    let categoryValidations = [];
    let stValidations = [];
    let isFishTypeValid = false;
    if(fishType && fishType.categories){
        if(!fishType.servingTypes)
            fishType.servingTypes = [];
        if(!fishType.fishType)
            fishType.fishType = "";
        isFormValid = true;
        categoryValidations = fishType.categories.map((category, i) => {
            if(category.category === null || category.category === ""
            || category.size === null || category.size === ""){
                isFormValid = false;
            }
            return {
                isCategoryValid:
                    category.category !== null && category.category !== "",
                isSizeValid: category.size !== null && category.size !== "",
            };
        });
        stValidations = fishType.servingTypes.map((servingType) => {
            if (servingType.name === null || servingType.name === "")
                isFormValid = false;
            return {
                isServingTypeValid:
                    servingType.name !== null && servingType.name !== "",
            };
        });
        isFishTypeValid = fishType.fishType !== null && fishType.fishType !== "";
        if(fishType.categories.length<=0) {isFormValid=false;}
        if(fishType.servingTypes.length<=0) {isFormValid=false;}
        if(!isFishTypeValid){isFormValid=false;}
    }
    const addEmptyST = () => {
        let fishTypeNew = { ...fishType };
        fishTypeNew.servingTypes.push({
            name: ""
        });
        setCurrent(fishTypeNew);
    };
    const deleteRowST = (i) => {
        let fishTypeNew = { ...fishType };
        fishTypeNew.servingTypes.splice(i, 1);
        setCurrent(fishTypeNew);
    };
    const onSTChange = (e, index) => {
        let fishTypeNew = { ...fishType };
        fishTypeNew.servingTypes = fishTypeNew.servingTypes.map((servingType, i) => {
            if (index === i) {
                return { name: e.target.value };
            } else {
                return { name: servingType.name };
            }
        });
        setCurrent(fishTypeNew);
    };
    const addEmpty = () => {
        let fishTypeNew = { ...fishType };
        // fishTypeNew.categories = fishTypeNew.categories.map(category=>{return {category: category.category, size:category.size}});
        fishTypeNew.categories.push({
            category: "",
            size: "",
        });
        setCurrent(fishTypeNew);
    };
    const deleteRow = (i) => {
        let fishTypeNew = { ...fishType };
        fishTypeNew.categories.splice(i, 1);
        setCurrent(fishTypeNew);
    };
    const onCategoryChange = (e, index) => {
        let fishTypeNew = { ...fishType };
        fishTypeNew.categories = fishTypeNew.categories.map((category, i) => {
            if (index === i) {
                return { category: e.target.value, size: category.size, label: {hi:e.target.value, mr: e.target.value, en: e.target.value} };
            } else {
                return { category: category.category, size: category.size, label: {hi:category.category, mr: category.category, en: category.category} };
            }
        });
        setCurrent(fishTypeNew);
    };
    const onSizeChange = (e, index) => {
        let fishTypeNew = { ...fishType };
        fishTypeNew.categories = fishTypeNew.categories.map((category, i) => {
            if (index === i) {
                return { category: category.category, size: e.target.value, label: {hi:category.category, mr: category.category, en: category.category} };
            } else {
                return { category: category.category, size: category.size, label: {hi:category.category, mr: category.category, en: category.category} };
            }
        });
        setCurrent(fishTypeNew);
    };

    const onFishTypeChange = (e)=>{
        let fishTypeNew = { ...fishType };
        fishTypeNew.fishType = e.target.value;
        setCurrent(fishTypeNew);
    };
    const onNameChange = (e) => {
        let fishTypeNew = { ...fishType };
        fishTypeNew.name = e.target.value;
        fishTypeNew.code = e.target.value;
        if(fishTypeNew.label)
            fishTypeNew.label = {en:e.target.value, ...fishTypeNew.label}
        else
            fishTypeNew.label = {en:e.target.value, mr: "", hi: ""}
        setCurrent(fishTypeNew);
    };
    const onNameChangeHi = (e) => {
        let fishTypeNew = { ...fishType };
        fishTypeNew.nameHi = e.target.value;
        setCurrent(fishTypeNew);
    };
    const onNameChangeMr = (e) => {
        let fishTypeNew = { ...fishType };
        fishTypeNew.nameMr = e.target.value;
        setCurrent(fishTypeNew);
    };

    const onSubmit = async () => {
        let isValid = true;
        fishType.categories.map((category, i) => {
            if (category.category === null || category.category === ""
                || category.size === null || category.size === "") {
                isFormValid = false;
            }
        });
        if (fishType.categories.length <= 0) {
            isValid = false;
        }
        if (fishType.fishType === null || fishType.fishType === "") {
            isValid = false;
        }
        if(fishType.name === null || fishType.name === "")
            isValid = false;
        if(fishType.nameMr === null || fishType.nameMr === "")
            isValid = false;
        if(fishType.nameHi === null || fishType.nameHi === "")
            isValid = false;
        if (isValid) {
            fishType.createdOn = firebase.firestore.Timestamp.fromDate(new Date());
            fishType.createdBy = currentUser && currentUser.id?currentUser.id:"ERROR";
            fishType.status = "ACTIVE";
            fishType.label = {en:fishType.name, mr: fishType.nameMr, hi: fishType.nameHi}
            delete fishType.nameHi
            delete fishType.nameMr
            await firestore.collection('fishTypes').doc(fishType.name).set(fishType);
            history.push(`${process.env.PUBLIC_URL}/fishTypes`)
        }
    };

    return !fishType || !fishType.categories ? (
        ""
    ) : (
        <Container>
            <Grid container>
                <Grid item xs={3}>
                    <TextField
                        label="Name(en)"
                        defaultValue={fishType.name}
                        variant="outlined"
                        fullWidth
                        size={"small"}
                        error={(fishType.name===null || fishType.name==="")}
                        onChange={(e) => {
                            onNameChange(e);
                        }}
                        style={{ marginTop: 25 }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Name(mr)"
                        defaultValue={fishType.nameMr}
                        variant="outlined"
                        fullWidth
                        size={"small"}
                        error={(fishType.nameMr===null || fishType.nameMr==="")}
                        onChange={(e) => {
                            onNameChangeMr(e);
                        }}
                        style={{ marginTop: 25 }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Name(hi)"
                        defaultValue={fishType.nameHi}
                        variant="outlined"
                        fullWidth
                        size={"small"}
                        error={(fishType.nameHi===null || fishType.nameHi==="")}
                        onChange={(e) => {
                            onNameChangeHi(e);
                        }}
                        style={{ marginTop: 25 }}
                    />
                </Grid>
            </Grid>
            <TextField
                label="Fish type"
                value={fishType.fishType}
                variant="outlined"
                fullWidth
                size={"small"}
                onChange={(e) => {
                    onFishTypeChange(e);
                }}
                error={!isFishTypeValid}
                style={{ marginTop: 25 }}
            />
            <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                    <Typography variant="subtitle1"
                                gutterBottom
                                style={{ textAlign: "center" }}>
                        Categories
                    </Typography>
                    <List>
                        {fishType.categories.map((category, i) => {
                            return (
                                <ListItem key={i} fullWidth>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Category"
                                                value={category.category}
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                style={{marginTop: 8}}
                                                onChange={(e) => {
                                                    onCategoryChange(e, i);
                                                }}
                                                error={!categoryValidations[i].isCategoryValid}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Size"
                                                value={category.size}
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                style={{marginTop: 8}}
                                                onChange={(e) => {
                                                    onSizeChange(e, i);
                                                }}
                                                error={!categoryValidations[i].isSizeValid}
                                            />
                                        </Grid>
                                    </Grid>
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="delete">
                                            <DeleteIcon
                                                color={'red'}
                                                onClick={() => {
                                                    deleteRow(i);
                                                }} />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                    <Button
                        onClick={() => {
                            addEmpty();
                        }}
                    >
                        Add a new category
                    </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1"
                                gutterBottom
                                style={{ textAlign: "center" }}>
                        Serving types
                    </Typography>
                    <List>
                        {fishType.servingTypes.map((servingType, i)=>{
                            return (
                                <ListItem key={i} fullWidth>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Serving type"
                                                value={servingType.name}
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                onChange={(e) => {
                                                    onSTChange(e, i);
                                                }}
                                                error={!stValidations[i].isServingTypeValid}
                                                style={{ marginTop: 8 }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="delete">
                                            <DeleteIcon
                                                color={'red'}
                                                onClick={() => {
                                                    deleteRowST(i);
                                                }} />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                    <Button
                        onClick={() => {
                            addEmptyST();
                        }}
                    >
                        Add new a Serving type
                    </Button>
                </Grid>
            </Grid>
            <Button
                disabled={!isFormValid}
                onClick={()=>{
                    onSubmit();
                }}
            >
                Submit
            </Button>
        </Container>
    );
};

const mapStateToProps = ({ admin, user }) => ({
    fishType: admin.current,
    currentUser: user.currentUser
});

const mapDispatchToProps = (dispatch) => ({
    setCurrent: (fishType) => dispatch(setAdminCurrent(fishType)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(FishTypeNewComponent)
);
