import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { firestore } from "../../../firebase/firebase.utils";
import Typography from "@material-ui/core/Typography";
import AdminRetailerEditComponent from "../../../components/admin/admin-retailer-edit/admin-retailer-edit.component";
import firebase from "../../../firebase/firebase.utils";
import {
    setAdminCountries,
    setAdminCurrent,
    setAdminLanguages, setAdminRetailerTypes,
    setAdminStates
} from "../../../redux/admin/admin.actions";

class AdminRetailerEditPage extends React.Component {
    unsubscribeSnapshot = null;

    componentWillMount() {}

    componentDidMount() {
        const { setCurrent, setCountries, setStates, setLanguages, setRetailerTypes } = this.props;
        const id = this.props.match.params.id;
        setCurrent(null);
        setCountries(null);
        setStates(null);
        setLanguages(null);
        setRetailerTypes(null);
        this.unsubscribeSnapshot = firestore
            .collection("retailers")
            .doc(id)
            .onSnapshot((docSnapshot) => {
                let retailer = docSnapshot.data();
                setCurrent(retailer);
            });

        //options
        firestore
            .collection("countries")
            .get()
            .then((querySnapshot) => {
                let countries = {};
                querySnapshot.forEach(function (doc) {
                    let country = doc.data();
                    countries[country.code] = country;
                });
                setCountries(countries);
            });
        firestore
            .collection("states")
            .get()
            .then((querySnapshot) => {
                let states = {};
                querySnapshot.forEach(function (doc) {
                    let state = doc.data();
                    states[state.code] = state;
                });
                setStates(states);
            });
        firestore
            .collection("languages")
            .get()
            .then((querySnapshot) => {
                let languages = {};
                querySnapshot.forEach(function (doc) {
                    let language = doc.data();
                    languages[language.code] = language;
                });
                setLanguages(languages);
            });
        firestore
            .collection("retailerTypes")
            .get()
            .then((querySnapshot) => {
                let retailerTypes = {};
                querySnapshot.forEach(function (doc) {
                    let retailerType = doc.data();
                    retailerTypes[retailerType.code] = retailerType;
                });
                setRetailerTypes(retailerTypes);
            });

        //setOptions
    }

    componentWillUnmount() {
        this.unsubscribeSnapshot();
        const { setCurrent } = this.props;
        setCurrent(null);
    }

    // createdOn: firebase.firestore.Timestamp.fromDate(new Date()),

    render() {
        return (
            <div>
                <Typography
                    variant="subtitle1"
                    gutterBottom
                    style={{ textAlign: "center" }}
                >
                    Edit retailer: #{this.props.match.params.id}
                </Typography>
                <AdminRetailerEditComponent />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setCurrent: (retailer) => dispatch(setAdminCurrent(retailer)),
    setCountries: (countries) => dispatch(setAdminCountries(countries)),
    setStates: (states) => dispatch(setAdminStates(states)),
    setLanguages: (languages) => dispatch(setAdminLanguages(languages)),
    setRetailerTypes: (retailerTypes) => dispatch(setAdminRetailerTypes(retailerTypes)),
});

export default withRouter(connect(null, mapDispatchToProps)(AdminRetailerEditPage));