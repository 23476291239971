import React from "react";
import "./fish-type-list.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper/Paper";
import Container from "@material-ui/core/Container";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import {firestore} from "../../../firebase/firebase.utils";
import Button from "@material-ui/core/Button";
import firebase from "../../../firebase/firebase.utils";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: "36ch",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
    },
}));

const FishTypeListComponent = ({fishTypes, history, currentUser}) => {
    const classes = useStyles();

    const deleteFishType = async (id) => {
        if(window.confirm("Are you sure to delete the fish type?")){
            await firestore
                .collection("fishTypes")
                .doc(id)
                .update({status: "INACTIVE", updatedOn: firebase.firestore.Timestamp.fromDate(new Date()), updatedBy: currentUser && currentUser.id?currentUser.id:"ERROR" });
        }
    };

    const downloadFishTypes = async () => {
        let fTData = {data: []};
        const snapshot = await firestore.collection('fishTypes').get();
        snapshot.forEach((doc) => {
            let fishType = doc.data();
            fTData.data.push(fishType);
        });
        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(fTData)], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = "data.json";
        document.body.appendChild(element);
        element.click();
    };

    return (
        <Container>
            <Button
                onClick={()=>{
                    history.push(`${process.env.PUBLIC_URL}/fishType/new`)
                }}
            >
                Add new fish type
            </Button>
            <Button
                onClick={()=>{
                    downloadFishTypes()
                }}
            >
                Download fish types json
            </Button>
            <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Sno</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Type</TableCell>
                            <TableCell align="left">Categories</TableCell>
                            <TableCell align="left">Serving Types</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(fishTypes).map((key, i) => {
                            const fishType = fishTypes[key];
                            return (
                                <TableRow
                                    key={`${fishType.name}`}
                                    hover={true}
                                    className={"clickable-row"}>
                                    <TableCell component="th" scope="row"
                                               onClick={()=>{
                                                   history.push(`${process.env.PUBLIC_URL}/fishType/edit/${fishType.name}`)
                                               }}>
                                        {i + 1}
                                    </TableCell>
                                    <TableCell align="left"
                                               onClick={()=>{
                                                   history.push(`${process.env.PUBLIC_URL}/fishType/edit/${fishType.name}`)
                                               }}>
                                        {fishType.name}
                                    </TableCell>
                                    <TableCell align="left"
                                               onClick={()=>{
                                                   history.push(`${process.env.PUBLIC_URL}/fishType/edit/${fishType.name}`)
                                               }}>
                                        {fishType.fishType?fishType.fishType:''}
                                    </TableCell>
                                    <TableCell align="left"
                                               onClick={()=>{
                                                   history.push(`${process.env.PUBLIC_URL}/fishType/edit/${fishType.name}`)
                                               }}>
                                        |
                                        {
                                            fishType.categories.map(category=>{
                                                return `${category.category}:${category.size} | `
                                            })
                                        }
                                    </TableCell>
                                    <TableCell
                                        onClick={()=>{
                                            history.push(`${process.env.PUBLIC_URL}/fishType/edit/${fishType.name}`)
                                        }}>
                                        {
                                            fishType.servingTypes?
                                            ("| " + fishType.servingTypes.map(servingType=>{
                                                return `${servingType.name} | `
                                            })):('')
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => {
                                                deleteFishType(fishType.name);
                                            }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

const mapStateToProps = ({ catalog, user }) => ({
    fishTypes: catalog.fishTypes,
    currentUser: user.currentUser
});

export default withRouter(connect(mapStateToProps)(FishTypeListComponent));
