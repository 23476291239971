import React from "react";
import "./retailer-consignment-close-form.scss";
import Container from "@material-ui/core/Container";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import firebase, { firestore } from "../../../../firebase/firebase.utils";
import { connect } from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
}));

const RetailerConsignmentCloseForm = ({ consignment }) => {
    const classes = useStyles();

    const hasRejected = (consignment) => {
        let hasRejected = false;
        for (let i = 0; i < consignment.transactions.length; i++) {
            if (consignment.transactions[i].status === "REJECTED")
                hasRejected = true;
        }
        return hasRejected;
    };
    const closeApprovedConsignment = async () => {
        // let batch = firestore.batch();
        let remarksText = document.getElementById(
            "close_approved_shipment_remarks"
        ).value;
        let prevRemarks = [...consignment.remarks];
        if (remarksText !== "") {
            prevRemarks.push({
                agentId: consignment.agentId,
                agentName: "",
                consignmentId: consignment.id,
                content: remarksText,
                createdOn: firebase.firestore.Timestamp.fromDate(new Date()),
                id: generateRandomString(16),
                nextStatus: "CLOSED",
                prevStatus: consignment.status,
                retailerId: consignment.retailerId,
                retailerName: "",
                status: "ACTIVE",
            });
        }
        let prevTransactions = [...consignment.transactions];
        prevTransactions.push({
            agentId: consignment.agentId,
            consignmentId: consignment.id,
            createdOn: firebase.firestore.Timestamp.fromDate(new Date()),
            id: generateRandomString(16),
            retailerId: consignment.retailerId,
            status: "CLOSED",
        });
        await firestore
            .collection("retailerConsignments")
            .doc(consignment.id)
            .update({
                status: "CLOSED",
                remarks: prevRemarks,
                transactions: prevTransactions,
            });
    };

    const closeDeniedRejectedConsignment = async () => {
        let updates = {};
        setRejectionQuantityError(false);
        if (rejectionConfirmation === "CANCEL") {
            let quantityMeasuredText = document.getElementById(
                "close_denied_rejected_shipment_quantity_measured"
            ).value;
            if (
                quantityMeasuredText === "" ||
                parseFloat(quantityMeasuredText) === 0
            ) {
                setRejectionQuantityError(true);
                return;
            }
            updates["quantityMeasured"] = parseFloat(quantityMeasuredText);
        }

        let remarksText = document.getElementById(
            "close_denied_rejected_shipment_remarks"
        ).value;
        let prevRemarks = [...consignment.remarks];
        if (remarksText !== "") {
            prevRemarks.push({
                agentId: consignment.agentId,
                agentName: "",
                consignmentId: consignment.id,
                content: remarksText,
                createdOn: firebase.firestore.Timestamp.fromDate(new Date()),
                id: generateRandomString(16),
                nextStatus: "CLOSED",
                prevStatus: consignment.status,
                retailerId: consignment.retailerId,
                retailerName: "",
                status: "ACTIVE",
            });
        }
        let prevTransactions = [...consignment.transactions];
        prevTransactions.push({
            agentId: consignment.agentId,
            consignmentId: consignment.id,
            createdOn: firebase.firestore.Timestamp.fromDate(new Date()),
            id: generateRandomString(16),
            retailerId: consignment.retailerId,
            status: "CLOSED",
        });
        updates["remarks"] = prevRemarks;
        updates["transactions"] = prevTransactions;
        updates["status"] = "CLOSED";

        await firestore
            .collection("retailerConsignments")
            .doc(consignment.id)
            .update(updates);
    };

    const closeDeniedAcceptedConsignment = async () => {
        let updates = {};
        setApprovedQuantityError(false);

        let quantityMeasuredText = document.getElementById(
            "close_denied_accepted_shipment_quantity_measured"
        ).value;
        if (
            quantityMeasuredText === "" ||
            parseFloat(quantityMeasuredText) === 0
        ) {
            setApprovedQuantityError(true);
            return;
        }
        updates["quantityMeasured"] = parseFloat(quantityMeasuredText);

        let remarksText = document.getElementById(
            "close_denied_accepted_shipment_remarks"
        ).value;
        let prevRemarks = [...consignment.remarks];
        if (remarksText !== "") {
            prevRemarks.push({
                agentId: consignment.agentId,
                agentName: "",
                consignmentId: consignment.id,
                content: remarksText,
                createdOn: firebase.firestore.Timestamp.fromDate(new Date()),
                id: generateRandomString(16),
                nextStatus: "CLOSED",
                prevStatus: consignment.status,
                retailerId: consignment.retailerId,
                retailerName: "",
                status: "ACTIVE",
            });
        }
        let prevTransactions = [...consignment.transactions];
        prevTransactions.push({
            agentId: consignment.agentId,
            consignmentId: consignment.id,
            createdOn: firebase.firestore.Timestamp.fromDate(new Date()),
            id: generateRandomString(16),
            retailerId: consignment.retailerId,
            status: "CLOSED",
        });
        updates["remarks"] = prevRemarks;
        updates["transactions"] = prevTransactions;
        updates["status"] = "CLOSED";
        updates["updatedOn"] = firebase.firestore.Timestamp.fromDate(new Date());

        await firestore
            .collection("retailerConsignments")
            .doc(consignment.id)
            .update(updates);
    };

    const generateRandomString = (length) => {
        let result = "";
        const characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }
        return result;
    };

    const [rejectionConfirmation, setRejectionConfirmation] = React.useState(
        "CONFIRM"
    );
    const [rejectionQuantityError, setRejectionQuantityError] = React.useState(
        false
    );
    const [approvedQuantityError, setApprovedQuantityError] = React.useState(
        false
    );

    const handleRejectionConfirmation = (event) => {
        setRejectionConfirmation(event.target.value);
    };

    return !consignment ? (
        ""
    ) : consignment.status !== "APPROVED" && consignment.status !== "DENIED" ? (
        ""
    ) : (
        <Container style={{ maxWidth: 650 }}>
            <Paper className={classes.paper}>
                <Typography variant={"h6"}>Close Shipment</Typography>
                {consignment.status === "APPROVED" ? (
                    <Box>
                        <TextField
                            id="close_approved_shipment_remarks"
                            label="Remarks"
                            multiline
                            rows={4}
                            defaultValue=""
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 25 }}
                        />
                        <br />
                        <Box style={{ textAlign: "right" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ marginTop: 25 }}
                                onClick={() => {
                                    closeApprovedConsignment();
                                }}
                            >
                                Close shipment
                            </Button>
                        </Box>
                    </Box>
                ) : consignment.status === "DENIED" &&
                  hasRejected(consignment) ? (
                    <Box>
                        <Select
                            id="close_denied_rejected_shipment_reject_confirm"
                            variant="outlined"
                            fullWidth
                            onChange={handleRejectionConfirmation}
                            value={rejectionConfirmation}
                            style={{ textAlign: "left" }}
                        >
                            <MenuItem value={"CONFIRM"}>
                                Confirm rejection
                            </MenuItem>
                            <MenuItem value={"CANCEL"}>
                                Cancel rejection
                            </MenuItem>
                        </Select>
                        <br />
                        {rejectionConfirmation === "CANCEL" ? (
                            <TextField
                                id="close_denied_rejected_shipment_quantity_measured"
                                label="Quantity measured"
                                defaultValue="0"
                                variant="outlined"
                                fullWidth
                                type="number"
                                error={rejectionQuantityError}
                                style={{ marginTop: 25 }}
                            />
                        ) : (
                            ""
                        )}
                        <br />
                        <TextField
                            id="close_denied_rejected_shipment_remarks"
                            label="Remarks"
                            multiline
                            rows={4}
                            defaultValue=""
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 25 }}
                        />
                        <br />
                        <Box style={{ textAlign: "right" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ marginTop: 25 }}
                                onClick={() => {
                                    closeDeniedRejectedConsignment();
                                }}
                            >
                                Close shipment
                            </Button>
                        </Box>
                    </Box>
                ) : consignment.status === "DENIED" &&
                  !hasRejected(consignment) ? (
                    <Box>
                        <TextField
                            id="close_denied_accepted_shipment_quantity_measured"
                            label="Quantity measured"
                            defaultValue="0"
                            variant="outlined"
                            fullWidth
                            type="number"
                            error={approvedQuantityError}
                            style={{ marginTop: 25 }}
                        />
                        <br />
                        <TextField
                            id="close_denied_accepted_shipment_remarks"
                            label="Remarks"
                            multiline
                            rows={4}
                            defaultValue=""
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 25 }}
                        />
                        <br />
                        <Box style={{ textAlign: "right" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ marginTop: 25 }}
                                onClick={() => {
                                    closeDeniedAcceptedConsignment();
                                }}
                            >
                                Close shipment
                            </Button>
                        </Box>
                    </Box>
                ) : (
                    ""
                )}
            </Paper>
        </Container>
    );
};
const mapStateToProps = ({ common }) => ({
    consignment: common.current,
});

export default connect(mapStateToProps)(RetailerConsignmentCloseForm);
