
const INITIAL_STATE = {
    current: null,
    countries: null,
    states: null,
    languages: null,
    retailerTypes: null,
    retailers: null,
    purchases: null,
    fishTypes: null,
    fishCategories: null,
    retailerWarehouses: null,
    customerProducts: null
};

const adminReducer = (state = INITIAL_STATE, action) => {
    const strategy = strategies[action.type] ?? strategies.__default__;
    return strategy(state, action);
};

const strategies = {
    SET_ADMIN_CURRENT: setAdminCurrent,
    SET_ADMIN_COUNTRIES: setAdminCountries,
    SET_ADMIN_STATES: setAdminStates,
    SET_ADMIN_LANGUAGES: setAdminLanguages,
    SET_ADMIN_RETAILER_TYPES: setAdminRetailerTypes,
    SET_ADMIN_RETAILERS: setAdminRetailers,
    SET_ADMIN_FISH_TYPES: setAdminFishTypes,
    SET_ADMIN_FISH_CATEGORIES: setAdminFishCategories,
    SET_ADMIN_RETAILER_WAREHOUSES: setAdminRetailerWarehouses,
    SET_ADMIN_PURCHASES: setAdminPurchases,
    SET_ADMIN_CUSTOMER_PRODUCTS: setAdminCustomerProducts,
    __default__: state => state
};

function setAdminCurrent(state, action) {
    return {
        ...state,
        current: action.payload
    };
}

function setAdminCountries(state, action) {
    return {
        ...state,
        countries: action.payload
    };
}

function setAdminStates(state, action) {
    return {
        ...state,
        states: action.payload
    };
}

function setAdminLanguages(state, action) {
    return {
        ...state,
        languages: action.payload
    };
}

function setAdminRetailerTypes(state, action) {
    return {
        ...state,
        retailerTypes: action.payload
    };
}
function setAdminRetailers(state, action) {
    return {
        ...state,
        retailers: action.payload
    };
}
function setAdminFishTypes(state, action) {
    return {
        ...state,
        fishTypes: action.payload
    };
}
function setAdminFishCategories(state, action) {
    return {
        ...state,
        fishCategories: action.payload
    };
}
function setAdminRetailerWarehouses(state, action) {
    return {
        ...state,
        retailerWarehouses: action.payload
    };
}
function setAdminPurchases(state, action) {
    return {
        ...state,
        purchases: action.payload
    };
}
function setAdminCustomerProducts(state, action) {
    return {
        ...state,
        customerProducts: action.payload
    };
}
export default adminReducer;