import React from "react";
import "./admin-new-order.component.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {
    setAdminRetailerWarehouses,
    setAdminCurrent,
} from "../../../redux/admin/admin.actions";
import { firestore } from "../../../firebase/firebase.utils";
import firebase from "../../../firebase/firebase.utils";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: "36ch",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
        overflowX: 'auto'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const AdminNewOrderComponent = ({
    form,
    purchases,
    retailers,
    fishTypes,
    warehouses,
    setCurrent,
    currentUser,
    setAdminRetailerWarehouses,
    history,
}) => {
    const classes = useStyles();

    let categoriesSelected = {};
    let selectedRetailer = {};
    let selectedPurchase = {};
    if (form !== null && fishTypes !== null) {
        for (let i = 0; i < form.length; i++) {
            const selectedFishType = fishTypes[form[i].fishType];
            if (selectedFishType && selectedFishType.categories) {
                categoriesSelected[i] = selectedFishType.categories;
            }
        }
    }
    if (retailers && form) {
        for (let i = 0; i < form.length; i++) {
            selectedRetailer[i] = retailers[form[i].retailerId];
            selectedPurchase[i] = purchases[form[i].purchaseId];
        }
    }

    let showWarehouseInput = {};
    if(form){
        for (let i = 0; i < form.length; i++) {
            if (form[i].warehouseId === "NEW") showWarehouseInput[i] = true;
        }
    }

    const handleChange = (e, attr, i) => {
        const value = e.target.value;
        let formNew = [ ...form ];
        formNew[i][attr] = value;
        if (attr === "retailerId") {
            firestore
                .collection("retailerWarehouses")
                .where("retailerId", "==", value)
                .get()
                .then((querySnapshot) => {
                    let warehousesNew = {...warehouses};
                    querySnapshot.forEach(function (doc) {
                        let warehouse = doc.data();
                        warehousesNew[i] = {};
                        warehousesNew[i][warehouse.id] = warehouse;
                    });
                    setAdminRetailerWarehouses(warehousesNew);
                });
        }
        setCurrent(formNew);
    };
    const handleDateChange = (e, i) => {
        let formNew = [...form];
        formNew[i]["orderDate"] = new Date(e.target.value);
        setCurrent(formNew);
    };

    const handleAddressChange = (e, attr, i) => {
        const value = e.target.value;
        let formNew = [...form];
        formNew[i]["warehouse"][attr] = value;
        setCurrent(formNew);
    };

    const addRow = () => {
        let formNew = [...form];
        formNew.push({
            orderDate: new Date(),
            retailerId: "",
            fishType: "",
            fishCategory: "",
            pricePerKg: 0,
            quantity: 0,
            paymentStatus: "UNPAID",
            warehouseId: "",
            warehouse: {
                city: "",
                country: "",
                id: "",
                landmark: "",
                name: "",
                pincode: "",
                retailerId: "",
                state: "",
                status: "",
                streetAddress: ""
            },
            dispatchedFrom: ""
        });
        setCurrent(formNew);
    };

    const removeRow = (i) => {
        let formNew = [...form];
        formNew.splice(i, 1);
        setCurrent(formNew);
    };

    const generateRandomNumber = (length) => {
        let result = "";
        const characters = "0123456789";
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }
        return result;
    };

    let errors = [];
    if(form){
        for (let i = 0; i < form.length; i++) {
            const row = form[i];
            let emptyError = {
                purchaseId: !(row.purchaseId && row.purchaseId!=="" && row.purchaseId!=="default"),
                orderDate: !row.orderDate,
                retailerId: !(row.retailerId && row.retailerId !== "" && row.retailerId !== "default"),
                // fishType: !(row.fishType && row.fishType!=="" && row.fishType!=="default"),
                // fishCategory: !(row.fishCategory && row.fishCategory!=="" && row.fishCategory!=="default"),
                pricePerKg: !(row.pricePerKg && row.pricePerKg!=="" && row.pricePerKg>0),
                quantity: !(row.quantity && row.quantity!=="" && row.quantity>0),
                paymentStatus: !(row.paymentStatus && row.paymentStatus!=="" && row.paymentStatus!=="default"),
                warehouseId: !(row.warehouseId && row.warehouseId!=="" && row.warehouseId!=="default"),
                warehouseName: (row.warehouseId && row.warehouseId === "NEW" && row.warehouse && !row.warehouse.name),
                warehouseStreetAddress: (row.warehouseId && row.warehouseId === "NEW" && row.warehouse && !row.warehouse.streetAddress),
                warehouseLandmark: (row.warehouseId && row.warehouseId === "NEW" && row.warehouse && !row.warehouse.landmark),
                warehouseCity: (row.warehouseId && row.warehouseId === "NEW" && row.warehouse && !row.warehouse.city),
                warehousePincode: (row.warehouseId && row.warehouseId === "NEW" && row.warehouse && !row.warehouse.pincode)
            };
            errors.push(emptyError);
        }
    }

    const isFormValid = ()=>{
        let isValid = true;
        if(!form)
            isValid = false;
        if(form && form.length===0)
            isValid = false;
        for (let i = 0; i < errors.length; i++) {
            Object.keys(errors[i]).map(key=>{
                if (errors[i][key])
                    isValid = false;
            })
        }
        Object.keys(selectedRetailer).map(key=>{
            if(!selectedRetailer[key])
                isValid = false;
        });
        Object.keys(selectedPurchase).map(key=>{
            if(!selectedPurchase[key])
                isValid = false;
        });
        return isValid;
    };

    const calculateMinSalesPrice = (purchase) => {
        const agentCost = purchase.agentCost;
        let percentCost = agentCost.auctioner + agentCost.commission + agentCost.partywala;
        let absCost = agentCost.cleaningCutting + agentCost.ice + agentCost.landing +
            agentCost.loader + agentCost.misc + agentCost.packaging +
            agentCost.transportation + agentCost.wastage;
        let agentCostTotal = (percentCost * purchase.fishermanPrice/100) + absCost;
        return agentCostTotal + purchase.fishermanPrice;
    };

    const calculateMagrin = (purchase, productPrice) => {
        const agentCost = purchase.agentCost;
        let percentCost = agentCost.auctioner + agentCost.commission + agentCost.partywala;
        let absCost = agentCost.cleaningCutting + agentCost.ice + agentCost.landing +
            agentCost.loader + agentCost.misc + agentCost.packaging +
            agentCost.transportation + agentCost.wastage;
        let agentCostTotal = (percentCost * purchase.fishermanPrice/100) + absCost;
        let marginAmount = productPrice - agentCostTotal - purchase.fishermanPrice;
        let margin = marginAmount * 100 / purchase.fishermanPrice;
        return (margin).toFixed(0);
    };

    const flag = isFormValid();

    const createOrder = async () => {
        if(!isFormValid()){
            return;
        }
        let batch = firestore.batch();

        for (let i = 0; i < form.length; i++) {
            let row = form[i];
            let selectedRowRetailer = selectedRetailer[i];
            let selectedRowPurchase = selectedPurchase[i];
            const totalAmount = row.quantity * row.pricePerKg;
            const productId = generateRandomNumber(16);
            const orderId = generateRandomNumber(16);
            const consignmentId = generateRandomNumber(16);
            const invoiceId = orderId;//generateRandomNumber(16);
            const warehouseId = generateRandomNumber(16);
            selectedRowPurchase.quantityToListInKg = selectedRowPurchase.quantityToListInKg - parseFloat(row.quantity);
            let margin = calculateMagrin(selectedRowPurchase, parseFloat(row.pricePerKg));
            if(margin<0){
                alert("Sales price is less than Purchase price(including all costs)");
                return;
            }
            let product = {
                createdBy: currentUser && currentUser.id?currentUser.id:"3P0U7bkFVNZV2TjUPFZbS4FdHNJ2",
                agentId: selectedRowPurchase.agentId,
                createdOn: firebase.firestore.Timestamp.fromDate(row.orderDate),
                fishCategory: selectedRowPurchase.fishCategory,
                fishType: selectedRowPurchase.fishType,
                id: productId,
                margin: parseFloat(margin),
                pricePerKg: parseFloat(parseFloat(row.pricePerKg).toFixed(2)),
                purchase: selectedRowPurchase,
                purchaseId: row.purchaseId,
                quantityInKg: parseFloat(row.quantity),
                quantityToSellInKg: 0,
                retailerId: row.retailerId,
                status: "BOOKED",
                updatedOn: null,
            };
            let order = {
                createdBy: currentUser && currentUser.id?currentUser.id:"3P0U7bkFVNZV2TjUPFZbS4FdHNJ2",
                consignmentCount: 1,
                createdOn: firebase.firestore.Timestamp.fromDate(new Date()),
                orderedOn: firebase.firestore.Timestamp.fromDate(row.orderDate),
                expectedShipmentDate: firebase.firestore.Timestamp.fromDate(
                    row.orderDate
                ),
                id: orderId,
                retailerId: row.retailerId,
                status: row.paymentStatus === "PAID" ? "PAID" : "CLOSED",
                updatedOn: null,
                warehouseId:
                    row.warehouseId === "NEW" ? warehouseId : row.warehouseId,
            };
            let consignment = {
                createdBy: currentUser && currentUser.id?currentUser.id:"eloq8lgyHwQNPierIPq4LJWcPdp1",
                agentId: selectedRowPurchase.agentId,
                amountPaid: totalAmount,
                arrivedOn: null,
                createdOn: firebase.firestore.Timestamp.fromDate(row.orderDate),
                dispatchedFrom: row.dispatchedFrom,
                dispatchedOn: null,
                id: consignmentId,
                orderId: orderId,
                pricePerKg: parseFloat(row.pricePerKg),
                productId: productId,
                quantity: parseFloat(row.quantity),
                quantityMeasured: parseFloat(row.quantity),
                retailerId: row.retailerId,
                status: "CLOSED",
                updatedOn: null,
                remarks: [],
                retailerProduct: product,
                transactions: [],
            };
            let invoice = {
                createdBy: currentUser && currentUser.id?currentUser.id:"3P0U7bkFVNZV2TjUPFZbS4FdHNJ2",
                accountDetails: {
                    accountNo: "38413341996",
                    branch: "State Bank of India, Bandra West",
                    ifsc: "SBIN0050458",
                    name: "Numer8 Analytics",
                    pan: "AAGCN0306F",
                },
                numer8Details: {
                    address: "37B Perry cross road",
                    city: "Mumbai",
                    country: "India",
                    email: "devleena@numer.in",
                    gstNo: "27AAGCN0306F1ZW",
                    name: "Numer8 Analytics Pvt Ltd",
                    phone: "+91 9916064956",
                    state: "Maharashtra",
                },
                createdOn: firebase.firestore.Timestamp.fromDate(new Date()),
                orderedOn: firebase.firestore.Timestamp.fromDate(row.orderDate),
                id: invoiceId,
                invoiceItems: [
                    {
                        fishCategory: selectedRowPurchase.fishCategory,
                        fishType: selectedRowPurchase.fishType,
                        id: invoiceId,
                        orderId: orderId,
                        pricePerKg: parseFloat(row.pricePerKg),
                        quantity: parseFloat(row.quantity),
                        retailerConsignmentId: consignmentId,
                        retailerId: row.retailerId,
                        retailerProductId: productId,
                        totalAmount: totalAmount,
                    },
                ],
                orderId: orderId,
                paymentMode: "",
                retailerDetails: {
                    address: selectedRowRetailer.address.streetAddress,
                    city: selectedRowRetailer.address.city,
                    country: selectedRowRetailer.address.country,
                    email: selectedRowRetailer.email,
                    gstNo: selectedRowRetailer.gstNo,
                    name: selectedRowRetailer.name,
                    phone: selectedRowRetailer.phone,
                    state: selectedRowRetailer.address.state,
                },
                retailerId: row.retailerId,
                status: row.paymentStatus,
                totalAmount: totalAmount,
                updatedOn: null,
            };
            if (row.warehouseId === "NEW") {
                let warehouse = {
                    createdBy: currentUser && currentUser.id?currentUser.id:"3P0U7bkFVNZV2TjUPFZbS4FdHNJ2",
                    city: row.warehouse.city,
                    country: selectedRowRetailer.address.country,
                    id: warehouseId,
                    landmark: row.warehouse.landmark,
                    name: row.warehouse.name,
                    pincode: row.warehouse.pincode,
                    retailerId: row.retailerId,
                    state: selectedRowRetailer.address.state,
                    status: "ACTIVE",
                    streetAddress: row.warehouse.streetAddress,
                };
                let warehouseRef = firestore.collection("retailerWarehouses").doc(warehouseId);
                batch.set(warehouseRef, warehouse);
            }

            let purRef = firestore.collection("purchases").doc(selectedRowPurchase.id);
            batch.set(purRef, selectedRowPurchase);
            let productRef = firestore.collection("retailerProducts").doc(productId);
            batch.set(productRef, product);
            let orderRef = firestore.collection("retailerOrders").doc(orderId);
            batch.set(orderRef, order);
            let consignmentRef = firestore.collection("retailerConsignments").doc(consignmentId);
            batch.set(consignmentRef, consignment);
            let invoiceRef = firestore.collection("retailerInvoices").doc(invoiceId);
            batch.set(invoiceRef, invoice);
        }
        batch.commit().then(function () {
            history.push(`${process.env.PUBLIC_URL}/`);
        });
    };

    return retailers && warehouses ? (
            <Container>
                <Typography variant={"h6"}>New order</Typography>
                <div style={{width: '100%', overflowX: 'auto'}}>
                    {form&&form.length>0?(<table className={"form-table"}>
                        <thead>
                            <tr>
                                <th>Sno</th>
                                <th>Order date*</th>
                                <th>Purchase*</th>
                                <th>Retailer*</th>
                                {/*<th>Fish type*</th>*/}
                                {/*<th>Fish category*</th>*/}
                                <th>Price per Kg*</th>
                                <th>Quantity*</th>
                                <th>Status*</th>
                                <th>Warehouse*</th>
                                <th>Dispatched from</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            form.map((row,i)=>{
                                return row.warehouse?(<tr>
                                    <td>{i+1}</td>
                                    <td>
                                        <input
                                            className={errors[i].orderDate?'error':""}
                                            value={(row.orderDate).toISOString().split('T')[0]}
                                            onChange={(e)=>{
                                                    handleDateChange(e, i)
                                                }
                                            }
                                            type={"date"}
                                        />
                                    </td>
                                    <td>
                                        <select
                                            value={row.purchaseId}
                                            className={errors[i].purchaseId?'error':""}
                                            onChange={(e) => {
                                                handleChange(e, "purchaseId", i);
                                            }}>
                                            <option value={"default"}>Select purchase</option>
                                            {Object.keys(purchases).map((key) => {
                                                let purchase = purchases[key];
                                                return (
                                                    <option
                                                        key={purchase.id}
                                                        value={purchase.id}
                                                    >
                                                        {`${purchase.id} - ${purchase.fishType}, ${purchase.fishCategory}(${purchase.caughtOn.toDate().toLocaleDateString()}, ${purchase.quantityToListInKg} Kg, Min. Price: ₹${calculateMinSalesPrice(purchase)})`}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            value={row.retailerId}
                                            className={errors[i].retailerId?'error':""}
                                            onChange={(e) => {
                                                handleChange(e, "retailerId", i);
                                            }}>
                                            <option value={"default"}>Select retailer</option>
                                            {Object.keys(retailers).map((key) => {
                                                let retailer = retailers[key];
                                                return (
                                                    <option
                                                        key={retailer.id}
                                                        value={retailer.id}
                                                    >
                                                        {retailer.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </td>
                                    {/*<td>*/}
                                        {/*<select*/}
                                            {/*value={row.fishType}*/}
                                            {/*className={errors[i].fishType?'error':""}*/}
                                            {/*onChange={(e) => {*/}
                                                {/*handleChange(e, "fishType", i);*/}
                                            {/*}}>*/}
                                            {/*<option value={"default"}>Select fish type</option>*/}
                                            {/*{Object.keys(fishTypes).map((key) => {*/}
                                                {/*let fishType = fishTypes[key];*/}
                                                {/*return (*/}
                                                    {/*<option*/}
                                                        {/*key={fishType.code}*/}
                                                        {/*value={fishType.code}*/}
                                                    {/*>*/}
                                                        {/*{fishType.name}*/}
                                                    {/*</option>*/}
                                                {/*);*/}
                                            {/*})}*/}
                                        {/*</select>*/}
                                    {/*</td>*/}
                                    {/*<td>*/}
                                        {/*<select*/}
                                            {/*value={row.fishCategory}*/}
                                            {/*className={errors[i].fishCategory?'error':""}*/}
                                            {/*onChange={(e) => {*/}
                                                {/*handleChange(e, "fishCategory", i);*/}
                                            {/*}}*/}
                                        {/*>*/}
                                            {/*<option value={"default"}>Select fish category</option>*/}
                                            {/*{categoriesSelected[i] ? Object.keys(categoriesSelected[i]).map((key) => {*/}
                                                {/*let category = categoriesSelected[i][key];*/}
                                                {/*return (*/}
                                                    {/*<option*/}
                                                        {/*key={category.category}*/}
                                                        {/*value={category.category}*/}
                                                    {/*>*/}
                                                        {/*{category.category} - {category.size}*/}
                                                    {/*</option>*/}
                                                {/*);*/}
                                            {/*}):""}*/}
                                        {/*</select>*/}
                                    {/*</td>*/}
                                    <td>
                                        <input
                                            value={row.pricePerKg}
                                            className={errors[i].pricePerKg?'error':""}
                                            onChange={(e) => {
                                                handleChange(e, "pricePerKg", i);
                                            }}
                                            type={"number"}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            value={row.quantity}
                                            className={errors[i].quantity?'error':""}
                                            onChange={(e) => {
                                                handleChange(e, "quantity", i);
                                            }}
                                            type={"number"}
                                        />
                                    </td>
                                    <td>
                                        <select
                                            value={row.paymentStatus}
                                            className={errors[i].paymentStatus?'error':""}
                                            onChange={(e) => {
                                                handleChange(e, "paymentStatus", i);
                                            }}
                                        >
                                            <option value={"default"}>Select payment status</option>
                                            <option value={"UNPAID"}>Not paid</option>
                                            <option value={"PAID"}>Paid</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            value={row.warehouseId}
                                            className={errors[i].warehouseId?'error':""}
                                            onChange={(e) => {
                                                handleChange(e, "warehouseId", i);
                                            }}
                                        >
                                            <option value={"default"}>Select warehouse</option>
                                            {warehouses[i]?Object.keys(warehouses[i]).map((key) => {
                                                let warehouse = warehouses[i][key];
                                                return (
                                                    <option
                                                        key={warehouse.id}
                                                        value={warehouse.id}
                                                    >
                                                        {warehouse.name}
                                                    </option>
                                                );
                                            }):""}
                                            <option value={"NEW"} color={"primary"}> + New a new warehouse</option>
                                        </select>
                                        <span className={showWarehouseInput && showWarehouseInput[i] ? "" : "hidden"}>
                        <div  className={"mc-form-control-1"}>
                            <label htmlFor="name">Warehouse name</label>
                            <input
                                className={errors[i].warehouseName?'error':""}
                                value={row.warehouse.name}
                                onChange={(e) => {
                                    handleAddressChange(e, "name", i);
                                }}
                            />
                        </div>
                        <div  className={"mc-form-control-1"}>
                            <label htmlFor="name">Address</label>
                            <input
                                className={errors[i].warehouseStreetAddress?'error':""}
                                value={row.warehouse.streetAddress}
                                onChange={(e) => {
                                    handleAddressChange(e, "streetAddress", i);
                                }}
                            />
                        </div>
                        <div  className={"mc-form-control-1"}>
                            <label htmlFor="name">Landmark</label>
                            <input
                                className={errors[i].warehouseLandmark?'error':""}
                                value={row.warehouse.landmark}
                                onChange={(e) => {
                                    handleAddressChange(e, "landmark", i);
                                }}
                            />
                        </div>
                        <div  className={"mc-form-control-1"}>
                            <label htmlFor="name">City</label>
                            <input
                                className={errors[i].warehouseCity?'error':""}
                                value={row.warehouse.city}
                                onChange={(e) => {
                                    handleAddressChange(e, "city", i);
                                }}
                            />
                        </div>
                        <div  className={"mc-form-control-1"}>
                            <label htmlFor="name">Pincode</label>
                            <input
                                className={errors[i].warehousePincode?'error':""}
                                value={row.warehouse.pincode}
                                onChange={(e) => {
                                    handleAddressChange(e, "pincode", i);
                                }}
                            />
                        </div>
                    </span>
                                    </td>
                                    <td>
                                        <input
                                            value={row.dispatchedFrom}
                                            onChange={(e) => {
                                                handleChange(e, "dispatchedFrom", i);
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <button
                                        onClick={()=>{
                                                removeRow(i);
                                            }
                                        }>
                                            Delete
                                        </button>
                                    </td>
                                </tr>):"";
                            })
                        }
                        </tbody>
                    </table>):""}
                    <button
                        onClick={()=>{
                            addRow();
                        }
                        }>
                        Add order
                    </button>
                    <button disabled={!flag} onClick={()=>{createOrder();}}>Submit</button>
                </div>
            </Container>
    ) : (
        ""
    );
};

const mapStateToProps = ({ admin, user }) => ({
    purchases: admin.purchases,
    retailers: admin.retailers,
    fishTypes: admin.fishTypes,
    warehouses: admin.retailerWarehouses,
    form: admin.current,
    currentUser: user.currentUser
});
const mapDispatchToProps = (dispatch) => ({
    setCurrent: (retailer) => dispatch(setAdminCurrent(retailer)),
    setAdminRetailerWarehouses: (retailers) =>
        dispatch(setAdminRetailerWarehouses(retailers)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AdminNewOrderComponent)
);


/*import React from "react";
import "./admin-new-order.component.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
    setAdminRetailerWarehouses,
    setCurrent,
} from "../../redux/common/common.actions";
import { firestore } from "../../firebase/firebase.utils";
import firebase from "../../firebase/firebase.utils";
import Button from "@material-ui/core/Button";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: "36ch",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const AdminNewOrderComponent = ({
    form,
    retailers,
    fishTypes,
    warehouses,
    setCurrent,
    setAdminRetailerWarehouses,
    history,
}) => {
    const classes = useStyles();

    let categoriesSelected = [];
    let selectedRetailer = null;
    if (form !== null && fishTypes !== null) {
        const selectedFishType = fishTypes[form.fishType];
        if (selectedFishType && selectedFishType.categories) {
            categoriesSelected = selectedFishType.categories;
        }
    }
    if (retailers && form) {
        selectedRetailer = retailers[form.retailerId];
    }

    let showWarehouseInput = false;
    if (form && form.warehouseId === "NEW") showWarehouseInput = true;

    const handleChange = (e, attr) => {
        const value = e.target.value;
        let formNew = { ...form };
        formNew[attr] = value;
        if (attr === "retailerId") {
            firestore
                .collection("retailerWarehouses")
                .where("retailerId", "==", value)
                .get()
                .then((querySnapshot) => {
                    let warehouses = {};
                    querySnapshot.forEach(function (doc) {
                        let warehouse = doc.data();
                        warehouses[warehouse.id] = warehouse;
                    });
                    setAdminRetailerWarehouses(warehouses);
                });
        }
        setCurrent(formNew);
    };
    const handleDateChange = (date) => {
        let formNew = { ...form };
        formNew["orderDate"] = date;
        setCurrent(formNew);
    };

    const handleAddressChange = (e, attr) => {
        const value = e.target.value;
        let formNew = { ...form };
        formNew["warehouse"][attr] = value;
        setCurrent(formNew);
    };

    const generateRandomNumber = (length) => {
        let result = "";
        const characters = "0123456789";
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }
        return result;
    };

    const createOrder = async () => {
        if (!selectedRetailer) {
            return;
        }
        let batch = firestore.batch();
        const totalAmount = form.quantity * form.pricePerKg;
        const productId = generateRandomNumber(16);
        const orderId = generateRandomNumber(16);
        const consignmentId = generateRandomNumber(16);
        const invoiceId = generateRandomNumber(16);
        const warehouseId = generateRandomNumber(16);
        let product = {
            agentId: null,
            createdOn: firebase.firestore.Timestamp.fromDate(form.orderDate),
            fishCategory: form.fishCategory,
            fishType: form.fishType,
            id: productId,
            margin: 0,
            purchase: null,
            purchaseId: null,
            quantityInKg: form.quantity,
            quantityToSellInKg: 0,
            retailerId: form.retailerId,
            status: "BOOKED",
            updatedOn: null,
        };
        let order = {
            consignmentCount: 1,
            createdOn: firebase.firestore.Timestamp.fromDate(form.orderDate),
            expectedShipmentDate: firebase.firestore.Timestamp.fromDate(
                form.orderDate
            ),
            id: orderId,
            retailerId: form.retailerId,
            status: form.paymentStatus === "PAID" ? "PAID" : "CLOSED",
            updatedOn: null,
            warehouseId:
                form.warehouseId === "NEW" ? warehouseId : form.warehouseId,
        };
        let consignment = {
            agentId: null,
            amountPaid: totalAmount,
            arrivedOn: null,
            createdOn: firebase.firestore.Timestamp.fromDate(form.orderDate),
            dispatchedFrom: null,
            dispatchedOn: null,
            id: consignmentId,
            orderId: orderId,
            pricePerKg: form.pricePerKg,
            productId: productId,
            quantity: form.quantity,
            quantityMeasured: form.quantity,
            retailerId: form.retailerId,
            status: "CLOSED",
            updatedOn: null,
            remarks: [],
            retailerProduct: product,
            transactions: [],
        };
        let invoice = {
            accountDetails: {
                accountNo: "38413341996",
                branch: "State Bank of India, Bandra West",
                ifsc: "SBIN0050458",
                name: "Numer8 Analytics",
                pan: "AAGCN0306F",
            },
            numer8Details: {
                address: "37B Perry cross road",
                city: "Mumbai",
                country: "India",
                email: "devleena@numer.in",
                gstNo: "27AAGCN0306F1ZW",
                name: "Numer8 Analytics Pvt Ltd",
                phone: "+91 9916064956",
                state: "Maharashtra",
            },
            createdOn: firebase.firestore.Timestamp.fromDate(form.orderDate),
            id: invoiceId,
            invoiceItems: [
                {
                    fishCategory: form.fishCategory,
                    fishType: form.fishType,
                    id: invoiceId,
                    orderId: orderId,
                    pricePerKg: form.pricePerKg,
                    quantity: form.quantity,
                    retailerConsignmentId: consignmentId,
                    retailerId: form.retailerId,
                    retailerProductId: productId,
                    totalAmount: totalAmount,
                },
            ],
            orderId: orderId,
            paymentMode: "",
            retailerDetails: {
                address: selectedRetailer.address.streetAddress,
                city: selectedRetailer.address.city,
                country: selectedRetailer.address.country,
                email: selectedRetailer.email,
                gstNo: selectedRetailer.gstNo,
                name: selectedRetailer.name,
                phone: selectedRetailer.phone,
                state: selectedRetailer.address.state,
            },
            retailerId: form.retailerId,
            status: form.paymentStatus,
            totalAmount: totalAmount,
            updatedOn: null,
        };
        if (form.warehouseId === "NEW") {
            let warehouse = {
                city: form.warehouse.city,
                country: selectedRetailer.address.country,
                id: warehouseId,
                landmark: form.warehouse.landmark,
                name: form.warehouse.name,
                pincode: form.warehouse.pincode,
                retailerId: form.retailerId,
                state: selectedRetailer.address.state,
                status: "ACTIVE",
                streetAddress: form.warehouse.streetAddress,
            };
            let warehouseRef = firestore.collection("retailerWarehouses").doc(warehouseId);
            batch.set(warehouseRef, warehouse);
        }

        let productRef = firestore.collection("retailerProducts").doc(warehouseId);
        batch.set(productRef, product);
        let orderRef = firestore.collection("retailerOrders").doc(orderId);
        batch.set(orderRef, order);
        let consignmentRef = firestore.collection("retailerConsignments").doc(consignmentId);
        batch.set(consignmentRef, consignment);
        let invoiceRef = firestore.collection("retailerInvoices").doc(invoiceId);
        batch.set(invoiceRef, invoice);

        batch.commit().then(function () {
            history.push("/");
        });
    };

    return retailers && fishTypes && warehouses ? (
        <Container>
            <Paper className={classes.paper}>
                <Typography variant={"h6"}>New order</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                label="Order date"
                                format="dd/MM/yyyy"
                                fullWidth
                                variant="outlined"
                                value={form.orderDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    "aria-label": "select order date",
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        <FormControl style={{marginTop: 25, width: '100%'}} variant="outlined">
                        <InputLabel id="retailer-label" style={{textAlign: "left"}}>Retailer</InputLabel>
                        <Select
                            labelId="retailer-label"
                            fullWidth
                            value={form.retailerId}
                            style={{ textAlign: "left" }}
                            onChange={(e) => {
                                handleChange(e, "retailerId");
                            }}
                        >
                            {Object.keys(retailers).map((key) => {
                                let retailer = retailers[key];
                                return (
                                    <MenuItem
                                        key={retailer.id}
                                        value={retailer.id}
                                    >
                                        {retailer.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        </FormControl>
                        <FormControl style={{marginTop: 25, width: '100%'}} variant="outlined">
                            <InputLabel id="fishtype-label" style={{textAlign: "left"}}>Fish type</InputLabel>
                            <Select
                            labelId={"fishtype-label"}
                            fullWidth
                            variant="outlined"
                            value={form.fishType}
                            style={{ textAlign: "left" }}
                            onChange={(e) => {
                                handleChange(e, "fishType");
                            }}
                        >
                            {Object.keys(fishTypes).map((key) => {
                                let fishType = fishTypes[key];
                                return (
                                    <MenuItem
                                        key={fishType.code}
                                        value={fishType.code}
                                    >
                                        {fishType.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        </FormControl>
                        <FormControl style={{marginTop: 25, width: '100%'}} variant="outlined">
                            <InputLabel id="fishcategory-label" style={{textAlign: "left"}}>Fish category</InputLabel>
                            <Select
                            labelId={"fishcategory-label"}
                            fullWidth
                            variant="outlined"
                            value={form.fishCategory}
                            style={{ textAlign: "left" }}
                            onChange={(e) => {
                                handleChange(e, "fishCategory");
                            }}
                        >
                            {Object.keys(categoriesSelected).map((key) => {
                                let category = categoriesSelected[key];
                                return (
                                    <MenuItem
                                        key={category.category}
                                        value={category.category}
                                    >
                                        {category.category} - {category.size}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        </FormControl>
                        <TextField
                            label="Price per Kg"
                            value={form.pricePerKg}
                            variant="outlined"
                            fullWidth
                            type={"number"}
                            style={{ marginTop: 25 }}
                            onChange={(e) => {
                                handleChange(e, "pricePerKg");
                            }}
                        />
                        <TextField
                            label="Quantity"
                            value={form.quantity}
                            variant="outlined"
                            fullWidth
                            type={"number"}
                            style={{ marginTop: 25 }}
                            onChange={(e) => {
                                handleChange(e, "quantity");
                            }}
                        />
                        <FormControl style={{marginTop: 25, width: '100%'}} variant="outlined">
                            <InputLabel id="status-label" style={{textAlign: "left"}}>Payment status</InputLabel>
                            <Select
                                labelId={"status-label"}
                                fullWidth
                                variant="outlined"
                                value={form.paymentStatus}
                                style={{ textAlign: "left" }}
                                onChange={(e) => {
                                    handleChange(e, "paymentStatus");
                                }}
                            >
                                <MenuItem value={"UNPAID"}>Not paid</MenuItem>
                                <MenuItem value={"PAID"}>Paid</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl style={{marginTop: 25, width: '100%'}} variant="outlined">
                            <InputLabel id="warehouse-label" style={{textAlign: "left"}}>Warehouse address</InputLabel>
                            <Select
                            labelId={"warehouse-label"}
                            fullWidth
                            variant="outlined"
                            value={form.warehouseId}
                            style={{ textAlign: "left" }}
                            onChange={(e) => {
                                handleChange(e, "warehouseId");
                            }}
                        >
                            {Object.keys(warehouses).map((key) => {
                                let warehouse = warehouses[key];
                                return (
                                    <MenuItem
                                        key={warehouse.id}
                                        value={warehouse.id}
                                    >
                                        {warehouse.name}
                                    </MenuItem>
                                );
                            })}
                            <MenuItem value={"NEW"} color={"primary"}>New a new warehouse</MenuItem>
                        </Select>
                        </FormControl>
                        <div className={showWarehouseInput ? "" : "hidden"}>
                            <TextField
                                label="Name"
                                value={form.warehouse.name}
                                variant="outlined"
                                fullWidth
                                style={{ marginTop: 25 }}
                                onChange={(e) => {
                                    handleAddressChange(e, "name");
                                }}
                            />
                            <TextField
                                label="Street address"
                                value={form.warehouse.streetAddress}
                                variant="outlined"
                                fullWidth
                                style={{ marginTop: 25 }}
                                onChange={(e) => {
                                    handleAddressChange(e, "streetAddress");
                                }}
                            />
                            <TextField
                                label="Landmark"
                                value={form.warehouse.landmark}
                                variant="outlined"
                                fullWidth
                                style={{ marginTop: 25 }}
                                onChange={(e) => {
                                    handleAddressChange(e, "landmark");
                                }}
                            />
                            <TextField
                                label="City"
                                value={form.warehouse.city}
                                variant="outlined"
                                fullWidth
                                style={{ marginTop: 25 }}
                                onChange={(e) => {
                                    handleAddressChange(e, "city");
                                }}
                            />
                            <TextField
                                label="Pincode"
                                value={form.warehouse.pincode}
                                variant="outlined"
                                fullWidth
                                style={{ marginTop: 25 }}
                                onChange={(e) => {
                                    handleAddressChange(e, "pincode");
                                }}
                            />
                        </div>

                        <Grid container spacing={1}>
                            <Grid xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ marginTop: 25 }}
                                    onClick={() => {
                                        createOrder();
                                    }}
                                >
                                    Create order
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    ) : (
        ""
    );
};

const mapStateToProps = ({ common }) => ({
    retailers: common.retailers,
    fishTypes: common.fishTypes,
    warehouses: common.retailerWarehouses,
    form: common.current,
});
const mapDispatchToProps = (dispatch) => ({
    setCurrent: (retailer) => dispatch(setCurrent(retailer)),
    setAdminRetailerWarehouses: (retailers) =>
        dispatch(setAdminRetailerWarehouses(retailers)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AdminNewOrderComponent)
);
*/