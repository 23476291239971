
const INITIAL_STATE = {
    current: null,
    countries: null,
    states: null,
    languages: null,
    retailerTypes: null,
    retailers: null,
    fishTypes: null,
    fishCategories: null,
    retailerWarehouses: null
};

const commonReducer = (state = INITIAL_STATE, action) => {
    const strategy = strategies[action.type] ?? strategies.__default__;
    return strategy(state, action);
};

const strategies = {
    SET_CURRENT: setCurrent,
    // SET_COUNTRIES: setCountries,
    // SET_STATES: setStates,
    // SET_LANGUAGES: setLanguages,
    // SET_RETAILER_TYPES: setRetailerTypes,
    // SET_ADMIN_RETAILERS: setRetailers,
    // SET_ADMIN_FISH_TYPES: setFishTypes,
    // SET_ADMIN_FISH_CATEGORIES: setFishCategories,
    // SET_ADMIN_RETAILER_WAREHOUSES: setRetailerWarehouses,
    __default__: state => state
};

function setCurrent(state, action) {
    return {
        ...state,
        current: action.payload
    };
}
//
// function setCountries(state, action) {
//     return {
//         ...state,
//         countries: action.payload
//     };
// }
//
// function setStates(state, action) {
//     return {
//         ...state,
//         states: action.payload
//     };
// }
//
// function setLanguages(state, action) {
//     return {
//         ...state,
//         languages: action.payload
//     };
// }
//
// function setRetailerTypes(state, action) {
//     return {
//         ...state,
//         retailerTypes: action.payload
//     };
// }
// function setRetailers(state, action) {
//     return {
//         ...state,
//         retailers: action.payload
//     };
// }
// function setFishTypes(state, action) {
//     return {
//         ...state,
//         fishTypes: action.payload
//     };
// }
// function setFishCategories(state, action) {
//     return {
//         ...state,
//         fishCategories: action.payload
//     };
// }
// function setRetailerWarehouses(state, action) {
//     return {
//         ...state,
//         retailerWarehouses: action.payload
//     };
// }

export default commonReducer;