import React from "react";
import "./fish-details.scss";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { withRouter } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    table: {},
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
}));

const FishDetails = ({ consignment }) => {
    const classes = useStyles();

    const createRows = (label, value) => ({ label, value });
    let rows = [];
    if (consignment) {
        rows.push(
            createRows("Fish type:", consignment.retailerProduct.fishType)
        );
        rows.push(
            createRows(
                "Fish Category:",
                consignment.retailerProduct.fishCategory
            )
        );
    }

    return !consignment ? (
        ""
    ) : (
        <Container style={{ maxWidth: 650 }}>
            <Paper className={classes.paper}>
                <Typography variant={"h6"}>Fish details</Typography>
                <TableContainer>
                    <Table
                        className={classes.table}
                        size="small"
                        aria-label="a dense table"
                    >
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.label}>
                                    <TableCell component="th" scope="row">
                                        {row.label}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.value}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Container>
    );
};
const mapStateToProps = ({common})=>({
    consignment: common.current
});
export default withRouter(connect(mapStateToProps)(FishDetails));
