import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Container from "@material-ui/core/Container";
import { firestore } from "../../../firebase/firebase.utils";
import Typography from "@material-ui/core/Typography";
import FishTypeEditComponent from "../../../components/admin/fish-type-edit/fish-type-edit.component";
import {setAdminCurrent} from "../../../redux/admin/admin.actions";

class FishTypeEditPage extends React.Component {
    unsubscribeSnapshot = null;

    componentDidMount() {
        const { setCurrent } = this.props;
        const id = this.props.match.params.id;
        this.unsubscribeSnapshot = firestore
            .collection("fishTypes")
            .doc(id)
            .onSnapshot((docSnapshot) => {
                let fishType = docSnapshot.data();
                setCurrent(fishType);
            });
    }

    componentWillUnmount() {
        this.unsubscribeSnapshot();
        const { setCurrent } = this.props;
        setCurrent(null);
    }

    render() {
        return (
            <Container>
                <Typography
                    variant="subtitle1"
                    gutterBottom
                    style={{ textAlign: "center" }}
                >
                    Fish type: #{this.props.match.params.id}
                </Typography>
                <FishTypeEditComponent/>
            </Container>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setCurrent: (fishType) => dispatch(setAdminCurrent(fishType)),
});

export default withRouter(
    connect(null, mapDispatchToProps)(FishTypeEditPage)
);
