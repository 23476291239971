export const setAdminCurrent = (current) => {
    return {
        type: 'SET_ADMIN_CURRENT',
        payload: current,
    };
};
export const setAdminCountries = (countries) => {
    return {
        type: 'SET_ADMIN_COUNTRIES',
        payload: countries,
    };
};
export const setAdminStates = (states) => {
    return {
        type: 'SET_ADMIN_STATES',
        payload: states,
    };
};
export const setAdminLanguages = (languages) => {
    return {
        type: 'SET_ADMIN_LANGUAGES',
        payload: languages,
    };
};
export const setAdminRetailerTypes = (retailerTypes) => {
    return {
        type: 'SET_ADMIN_RETAILER_TYPES',
        payload: retailerTypes,
    };
};
export const setAdminRetailers = (retailers) => {
    return {
        type: 'SET_ADMIN_RETAILERS',
        payload: retailers,
    };
};
export const setAdminFishTypes = (fishTypes) => {
    return {
        type: 'SET_ADMIN_FISH_TYPES',
        payload: fishTypes,
    };
};
export const setAdminFishCategories = (fishCategories) => {
    return {
        type: 'SET_ADMIN_FISH_CATEGORIES',
        payload: fishCategories,
    };
};
export const setAdminRetailerWarehouses = (warehouses) => {
    return {
        type: 'SET_ADMIN_RETAILER_WAREHOUSES',
        payload: warehouses,
    };
};
export const setAdminPurchases = (purchases) => {
    return {
        type: 'SET_ADMIN_PURCHASES',
        payload: purchases,
    };
};
export const setAdminCustomerProducts = (products) => {
    return {
        type: 'SET_ADMIN_CUSTOMER_PRODUCTS',
        payload: products,
    };
};