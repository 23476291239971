import React from "react";

import { connect } from "react-redux";
import FilterGroup from "../../filter-group/filter-group.component";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Container from "@material-ui/core/Container";

class AdminWork extends React.Component {
    // b2bItems = {
    //     title: "B2B",
    //     filters: [
    //         {
    //             id: "Retailers",
    //             title: "Retailers",
    //             description: "B2B customers",
    //             url: "/admin/retailers",
    //         },
    //         {
    //             id: "New Retailer",
    //             title: "Create Retailer",
    //             description: "Create a new Retailer profile",
    //             url: "/admin/retailer/new",
    //         },
    //         {
    //             id: "Orders",
    //             title: "Orders",
    //             description: "B2B Orders",
    //             url: "/admin/b2b-orders",
    //         },
    //         {
    //             id: "Bulk upload B2B Orders",
    //             title: "Bulk order creation",
    //             description: "Create B2B orders",
    //             url: "/admin/b2b-order/new",
    //         },
    //     ],
    // };

    b2bRetailers = {
        title: "B2B Retailers",
        filters: [
            {
                id: "Retailers",
                title: "Retailers",
                description: "B2B customers",
                url: "/admin/retailers",
            },
            {
                id: "New Retailer",
                title: "Create Retailer",
                description: "Create a new Retailer profile",
                url: "/admin/retailer/new",
            }
        ],
    };

    b2bFilters = {
        title: "B2B Shipments",
        filters: [
            {
                id: "Approved",
                title: "Approved",
                description: "Shipments that are approved by the agent. Confirm and close them",
                url: "/retailerConsignments?status=APPROVED",
            },
            {
                id: "Disputed",
                title: "Disputed",
                description: "Accepted/Rejected Shipments that are denied by agent. Take further action to close them",
                url: "/retailerConsignments?status=DENIED",
            },
        ],
    };

    b2bOrderFilters = {
        title: "B2B Orders",
        filters: [
            {
                id: "All Orders",
                title: "All orders",
                description: "All B2B Orders",
                url: "/admin/b2b-orders",
            },
            {
                id: "Closed",
                title: "Closed",
                description: "Closed orders. Confirm the payment status from the Retailer",
                url: "/retailerOrders?status=CLOSED",
            },
            {
                id: "Bulk upload B2B Orders",
                title: "Bulk order creation",
                description: "Create B2B orders",
                url: "/admin/b2b-order/new",
            },
        ],
    };

    b2cOrderFilters = {
        title: "B2C Orders",
        filters: [
            // {
            //     id: "All Orders",
            //     title: "All orders",
            //     description: "All B2B Orders",
            //     url: "/admin/b2b-orders",
            // },
            // {
            //     id: "Closed",
            //     title: "Closed",
            //     description: "Closed orders. Confirm the payment status from the Retailer",
            //     url: "/retailerOrders?status=CLOSED",
            // },
            {
                id: "Bulk upload B2C Orders",
                title: "Bulk order creation",
                description: "Create B2C orders",
                url: "/admin/b2c-order/new",
            },
        ],
    };

    // b2bOrderFilters = {
    //     title: "B2B Orders",
    //     filters: [
    //         {
    //             id: "Closed",
    //             title: "Closed",
    //             description: "Closed orders. Confirm the payment status from the Retailer",
    //             url: "/retailerOrders?status=CLOSED",
    //         },
    //     ],
    // };
    //
    // b2cFilters = {
    //     title: "B2C Orders",
    //     filters: [
    //         {
    //             id: "Dispatched",
    //             title: "Dispatched",
    //             url: "/customerOrders?status=DISPATCHED",
    //         },
    //         {
    //             id: "Delivered",
    //             title: "Delivered",
    //             url: "/customerOrders?status=DELIVERED",
    //         },
    //         {
    //             id: "Unpaid",
    //             title: "Unpaid",
    //             url: "/customerOrders?status=UNPAID",
    //         },
    //         {
    //             id: "Paid",
    //             title: "Paid",
    //             url: "/customerOrders?status=PAID",
    //         },
    //     ],
    // };

    render() {
        const { classes } = this.props;
        return (
            <Container>
                <div className={classes.root}>
                    {/*<Typography*/}
                        {/*variant="h6"*/}
                        {/*gutterBottom*/}
                        {/*style={{ textAlign: "center", color: "#333" }}*/}
                    {/*>*/}
                    {/*</Typography>*/}
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FilterGroup filter={this.b2bRetailers} />
                        </Grid>
                        <Grid item xs={12}>
                            <FilterGroup filter={this.b2bOrderFilters} />
                        </Grid>
                        <Grid item xs={12}>
                            <FilterGroup filter={this.b2bFilters} />
                        </Grid>
                        <Grid item xs={12}>
                            <FilterGroup filter={this.b2cOrderFilters} />
                        </Grid>
                    </Grid>
                </div>
            </Container>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({});

const styles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
}));

export default withStyles(styles)(
    connect(null, mapDispatchToProps)(AdminWork)
);
