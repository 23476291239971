import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {setAdminCurrent} from "../../../redux/admin/admin.actions";
import {firestore} from "../../../firebase/firebase.utils";
import firebase from "../../../firebase/firebase.utils";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: "36ch",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const AdminRetailerNewComponent = ({retailer, countries, states, languages, retailerTypes, setCurrent, currentUser, history}) => {
    const classes = useStyles();

    let statesSelected = [];
    if(retailer!==null && states!==null){
        Object.keys(states).map(key=>{
            let state = states[key];
            if(state.country===retailer.country)
                statesSelected[state.code] = state;
        });
    }

    const handleChange = (e, attr)=>{
        const value = e.target.value;
        let retailerNew = {...retailer};
        retailerNew[attr] = value;
        if(attr==="country" || attr==="state")
            retailerNew["address"][attr] = value;
        setCurrent(retailerNew);
    };
    const handleAddressChange = (e, attr)=>{
        const value = e.target.value;
        let retailerNew = {...retailer};
        retailerNew["address"][attr] = value;
        setCurrent(retailerNew);
    };

    const updateRetailer = async () => {
        let retailerNew = {...retailer};
        if(isFormValid()){
            //https://us-central1-ofish-53c9f.cloudfunctions.net/createRetailer
            const requestOptions = {
                method: 'POST',
                crossDomain:true,
                body: JSON.stringify({
                    createdBy: currentUser.id,
                    name: retailer.name,
                    phone: retailer.phone,
                    country: retailer.country,
                    state: retailer.state,
                    pincode: retailer.address.pincode,
                    city: retailer.address.city,
                    streetAddress: retailer.address.streetAddress,
                    gstNo: retailer.gstNo,
                    language: retailer.language,
                    retailerType: retailer.retailerType
                })
            };
            console.log(requestOptions);
            fetch('https://us-central1-ofish-53c9f.cloudfunctions.net/createRetailer', requestOptions)
                .then(response => response.json())
                .then(data => {
                    if(!data.error){
                        history.push(`${process.env.PUBLIC_URL}/admin/retailers`)
                    }else{

                    }
                });
        }
        else {
            console.log("N vlaid");
        }
        // retailerType, language, gstNo, streetAddress, city, pincode, state, country, phone, name
        // console.log();
    };

    const isFormValid = () => {
        return !!retailer.phone.match(/^\+?\d{10,14}$/);

    };

    return retailer !==null && retailer.address &&countries!==null&&states!==null&&languages!==null&&retailerTypes!==null?(
        <Container>
            <Paper className={classes.paper}>
                <Typography variant={"h6"}>New retailer</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Name"
                            value={retailer.name}
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 25 }}
                            onChange={e=>{
                                handleChange(e, "name")
                            }}
                        />
                        <TextField
                            label="Phone"
                            value={retailer.phone}
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 25 }}
                            onChange={e=>{
                                handleChange(e, "phone")
                            }}
                        />
                        <Select
                            fullWidth
                            variant="outlined"
                            value={retailer.retailerType}
                            style={{ textAlign: "left", marginTop: 25 }}
                            onChange={e=>{
                                handleChange(e, "retailerType")
                            }}
                        >
                            {
                                Object.keys(retailerTypes).map(key=>{
                                    let retailerType = retailerTypes[key];
                                    return (<MenuItem key={retailerType.code} value={retailerType.code}>{retailerType.name}</MenuItem>)
                                })
                            }
                        </Select>
                        <Select
                            fullWidth
                            variant="outlined"
                            value={retailer.language}
                            style={{ textAlign: "left", marginTop: 25 }}
                            onChange={e=>{
                                handleChange(e, "language")
                            }}
                        >
                            {
                                Object.keys(languages).map(key=>{
                                    let language = languages[key];
                                    return (<MenuItem key={language.code} value={language.code}>{language.name}</MenuItem>)
                                })
                            }
                        </Select>
                        <Select
                            fullWidth
                            variant="outlined"
                            value={retailer.country}
                            style={{ textAlign: "left", marginTop: 25 }}
                            onChange={e=>{
                                handleChange(e, "country")
                            }}
                        >
                            {
                                Object.keys(countries).map(key=>{
                                    let country = countries[key];
                                    return (<MenuItem key={country.code} value={country.code}>{country.name}</MenuItem>)
                                })
                            }
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Street address"
                            value={retailer.address.streetAddress}
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 25 }}
                            onChange={e=>{
                                handleAddressChange(e, "streetAddress")
                            }}
                        />
                        <TextField
                            label="City"
                            value={retailer.address.city}
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 25 }}
                            onChange={e=>{
                                handleAddressChange(e, "city")
                            }}
                        />
                        <TextField
                            label="Pincode"
                            value={retailer.address.pincode}
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 25 }}
                            onChange={e=>{
                                handleAddressChange(e, "pincode")
                            }}
                        />
                        <Select
                            fullWidth
                            variant="outlined"
                            value={retailer.state}
                            style={{ textAlign: "left", marginTop: 25 }}
                            onChange={e=>{
                                handleChange(e, "state")
                            }}
                        >
                            {
                                Object.keys(statesSelected).map(key=>{
                                    let state = statesSelected[key];
                                    return (<MenuItem key={state.code} value={state.code}>{state.name}</MenuItem>)
                                })
                            }
                        </Select>
                        <TextField
                            label="GST No"
                            value={retailer.gstNo}
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 25 }}
                            onChange={e=>{
                                handleChange(e, "gstNo")
                            }}
                        />
                    </Grid>
                </Grid>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 25 }}
                    onClick={() => {
                        updateRetailer();
                    }}
                >
                    Add
                </Button>
            </Paper>
        </Container>
    ): "";
};

const mapStateToProps = ({ admin, user }) => ({
    retailer: admin.current,
    countries: admin.countries,
    states: admin.states,
    languages: admin.languages,
    retailerTypes: admin.retailerTypes,
    currentUser: user.currentUser
});
const mapDispatchToProps = (dispatch) => ({
    setCurrent: (retailer) => dispatch(setAdminCurrent(retailer))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminRetailerNewComponent));
